.w-form {
    max-width: 600px;
    max-width: 650px;
    width: 650px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .form-title {
    font-size: 24px;
    font-weight: 500;
    color: #2D3436;
    margin-bottom: 1.5rem;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .radio-label {
    background: #F8F9FA;
    border: 1px solid #E9ECEF;
    border-radius: 8px;
    padding: 1rem 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .radio-label.active-check {
    background: #F1F7FF;
    border-color: #0066FF;
  }
  
  .radio-label .radio-circle {
    width: 24px;
    height: 24px;
    border: 2px solid #CED4DA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .radio-label.active-check .radio-circle {
    border-color: #0066FF;
  }
  
  .radio-label.active-check .radio-circle::after {
    content: '';
    width: 12px;
    height: 12px;
    background: #0066FF;
    border-radius: 50%;
  }
  
  .radio-text {
    font-size: 16px;
    color: #2D3436;
  }
  
  .form-footer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .back-button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .next-button {
    background: #2D3436;
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .next-button:disabled {
    background: #E9ECEF;
    cursor: not-allowed;
  }
  
  /* Additional form styles */
  .additional-form {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
  }
  
  .form-control {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #b8adad;
    background: transparent;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    resize: none;
  }
  
  .form-control:focus {
    outline: none;
    border-color: #0066FF;
  }
  
  .form-control::placeholder {
    color: #ADB5BD;
  }
  
  textarea.form-control {
    min-height: 100px;
  }
  
  .submit-button {
    background: #0066FF;
    color: white;
    border: none;
    padding: 12px 32px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    width: auto;
  }
  .btncust{
      margin: 0 0 0 auto;
  }
  .btn-gray {
    background-color: #c7c2c2;
    border: 2px solid #c7c2c2;
    color: #fff;
    display: block;
    padding: 10px 30px;
    text-align: center;
    transition: all .3s;
    cursor: not-allowed;
}
  .submit-button:disabled {
    background: #E9ECEF;
    cursor: not-allowed;
  }
  
  /* Animation */
  .fade-in {
    animation: fadeIn 0.3s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } 
  .error-text{
    margin: 0;
    padding: 0;
    font-size: 11px;
  }
  .otp-input{
    font-size: 20px;
    text-align: center;
    font-weight: 800;
    color: black;
  }