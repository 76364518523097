.bmi-title {
    font-size: 22px;
    color: black;
}

.bmi-switch .ant-switch {
    width: 80px;
    height: 40px;
}

.bmi-switch.ant-switch-checked {
    background-color: #D17C4C !important;
}

.bmi-switch.ant-switch {
    background-color: #e2abab !important;
}

.ant-switch-inner-unchecked {
    line-height: 18px !important;
}

.bmi-switch .ant-switch-inner-checked {
    font-size: 18px !important;
}

.bmi-switch .ant-switch-inner-unchecked {
    font-size: 18px !important;
}

.bmi-input .floating-label {
    position: relative;
    margin-top: 20px;
}

.bmi-input .floating-label input {
    border-radius: 5px !important;
    background-color: transparent !important;
    border: 1px solid #b8adad !important;
    min-height: 50px !important;
}

:where(.css-dev-only-do-not-override-128z1ng).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    border-color: red !important;
}

.bmi-input .floating-label label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background: white;
    padding: 0 5px;
    color: #333;
    transition: 0.2s;
    pointer-events: none;
}

.bmi-input .floating-label input:focus+label,
.bmi-input .floating-label input:not(:placeholder-shown)+label {
    top: -3px;
    left: 10px;
    font-size: 12px;
    color: #333;
}

.divider {
    margin-top: 20px;
    margin-bottom: 25px;
    width: 100%;
    height: 1px;
    background-color: #bfbebe;
    border-radius: 20px;
}

.bmi-result {
    text-align: center;
    background: #e2b9b338;
    border-radius: 10px;
}

.segment-bar {
    display: flex;
    width: 100%;
    height: 10px;
    border-radius: 15px;
    overflow: visible;
    /* Ensure the indicator is not clipped */
    position: relative;
}

.segment {
    height: 100%;
    position: relative;
}

.underweight {
    background-color: #add8e6b5;
    flex: 1;
    /* 14.3% of the total width */
    border-radius: 10px 0 0 10px;
}

.healthy {
    background-color: #008000a1;
    flex: 3;
    /* 42.8% of the total width */
}

.overweight {
    background-color: #eeee1bc4;
    flex: 3;
    /* 42.8% of the total width */
}

.obese {
    background-color: #ff0000a3;
    flex: 3;
    /* 42.8% of the total width */
    border-radius: 0 10px 10px 0;
}

.indicator {
    width: 18px;
    height: 18px;
    background-color: white;
    border: 2px solid black;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    /* Position the indicator above the bar */
    left: 0;
    transform: translateX(-50%);
    z-index: 10;
    /* Ensure indicator is above the segments */
    transition: left 0.5s ease-in-out;
}

.labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    position: relative;
}

.label {
    font-size: 14px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
}

.label.underweight-label {
    left: 7%;
    /* Centered in the first segment */
}

.label.healthy-label {
    left: 24%;
    /* Centered in the second segment */
}

.label.overweight-label {
    left: 55%;
    /* Centered in the third segment */
}

.label.obese-label {
    left: 85%;
    /* Centered in the fourth segment */
}

.p-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.mbb-10 {
    margin-bottom: 10px !important;
}

/* 9-2-2025 */
html body .bmi-input .floating-label input:focus+label,
html body .bmi-input .floating-label input:not(:placeholder-shown)+label {
    top: auto;
    left: auto;
    bottom: -6px;
    font-size: 12px;
    color: #333;
    background: transparent;
    right: 5px;
    font-family: 'metropill' !important;
}

/* new switch */
/* Custom styling for the BMI switches */
.bmi-switch.ant-switch {
    min-width: 110px !important;
    height: 35px !important;
    background: #f5f5f5 !important;
    border: 1px solid #d9d9d9 !important;
}

.bmi-switch .ant-switch-handle {
    width: 40px !important;
    height: 28px !important;
    top: 1px !important;
    background: #fff !important;
    border-radius: 2px !important;
}

.bmi-switch.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 40px - 1px) !important;
}

.bmi-switch .ant-switch-inner {
    margin: 0 40px 0 6px !important;
    color: #000 !important;
}

.bmi-switch.ant-switch-checked .ant-switch-inner {
    margin: 0 6px 0 40px !important;
}

.bmi-switch:not(.ant-switch-checked) {
    background: #fff !important;
}

.bmi-switch.ant-switch-checked {
    background: #fff !important;
}

/* Ensure text is properly centered and sized */
.bmi-switch .ant-switch-inner-checked,
.bmi-switch .ant-switch-inner-unchecked {
    font-size: 14px !important;
    line-height: 30px !important;
    position: relative !important;
    z-index: 999;
}

.bmi-switch .ant-switch-inner {
    background: transparent !important;
    border-radius: 0 !important;
    position: relative;
    z-index: 999999;
}

.bmi-switch .ant-switch-inner,
.bmi-switch.ant-switch-checked .ant-switch-inner,
:where(.css-dev-only-do-not-override-ccdg5a).ant-switch.ant-switch-checked .ant-switch-inner {
    margin: 0px !important;
    padding: 0px !important;
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0 !important;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
:where(.css-dev-only-do-not-override-ccdg5a).ant-switch .ant-switch-inner .ant-switch-inner-checked {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    margin: 0 !important;
    /* color: #fff !important; */
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-unchecked,
:where(.css-dev-only-do-not-override-ccdg5a).ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    margin: 0 !important;
    /* color: #202f32 !important; */
}

.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.ant-switch .ant-switch-inner .ant-switch-inner-checked,
.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-unchecked,
.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    margin: 0 !important;
}

.bmi-switch .ant-switch-handle {
    width: 40px !important;
    height: 28px !important;
    top: 0 !important;
    background: #202f32 !important;
    border-radius: 2px !important;
    width: 50% !important;
    border-radius: 0 !important;
    height: 100% !important;
}

.bmi-switch .ant-switch-handle:before {
    background: #202f32 !important;
    border-radius: 0 !important;
}

.bmi-switch .ant-switch-handle {
    width: 50% !important;
    left: 50% !important;
}

.bmi-switch.ant-switch-checked .ant-switch-handle {
    width: 50% !important;
    left: 0 !important;
}

span.ant-switch-inner>* {
    align-items: center !important;
    width: 50% !important;
    display: flex !important;
    justify-content: center !important;
    padding: 5px !important;
    text-align: center !important;
}


.w-form-bmi .bmi-section-main .bmi-switch.ant-switch {
    background: #fff !important;
    overflow: hidden !important;
    border: 1px solid #202f32 !important;
    border-radius: 0 !important;
}

.w-form-bmi .bmi-switch span.ant-switch-inner span.ant-switch-inner-unchecked,
.w-form-bmi .bmi-switch.ant-switch-checked span.ant-switch-inner span.ant-switch-inner-checked {
    color: #202f32 !important;
    transition: 0.3s;
    color: #fff !important;
    /* background-color: #f00; */
}

.w-form-bmi .bmi-switch span.ant-switch-inner span.ant-switch-inner-checked,
.w-form-bmi .bmi-switch.ant-switch-checked span.ant-switch-inner span.ant-switch-inner-unchecked {
    color: #fff !important;
    transition: 0.3s;
    color: #202f32 !important;
    /* background-color: #f0f; */
}