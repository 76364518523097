/* CheckoutStyle.css */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.clinic-address{
    color: rgb(207, 176, 159);
    border: 1px solid #cbcbcb;
    padding: 10px;
    border-radius: 5px;
}
.checkout-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.checkout-heading {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
}
.theme-font{
    font-family: 'Playfair Display', serif !important;

}
.slot-area{
    border:1px solid gray  !important;
    border-radius: 10px;
    padding:10px 20px;
    margin-left: 10px;
}

.time-slots-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: 'Playfair Display', serif;
}

.time-slot-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust spacing between time slots */
}

.time-slot {
    flex: 0 0 calc(22% - 10px); /* Adjust width of each time slot (25% for 4 slots per row) */
    margin-bottom: 10px;
    padding: 10px;

    border: 1px solid #cdcaca;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    text-align: center; /* Center text */
}

.time-slot.selected {
    background-color: #D17C4C;
    color: #fff;
}


.time-slot:hover {

    background-color: #D17C4C;
    color: #fff;
}



  .order-summary{
    border: 1px solid rgb(221, 221, 221);
    border-radius: 10px;
  }
  .order-summary-two{
    border: none;
    background: #efefef;
    border-radius: 20px;
  }

  .btn-payment{
    margin-top: 10px;
    color: #fff;
    color: whitesmoke;
    background-color: #5474e5;
    border: 1px solid #4762c5;
    border-radius: 15px;

  }
  .btn-payment:hover,
  .btn-payment:active,
  .btn-payment:focus,
  .btn-payment:disabled {
    background-color: #4762c5 !important; 
    color: whitesmoke !important;
  }
  .badge-error {
    background: #fff5e1;
    color: red;
  }
  .app-card{
    border: none;
    border-radius: 20px;
    background: #f9f8f6;
  }
  .app-header{
    background: transparent;
    border: none;
    padding-top: 20px;
  }
  .app-title{
    font-size: 12px;
  }
  .app-desc{
    color: black;
    font-size: 13px;
    font-family: sans-serif;
  }
  .app-detail{
    color: #3d3e3f;
    font-family: sans-serif;
  }
  .bmp-heading{
    font-size: 20px !important;
    text-align: left;
  }
  .bmp-item-desc{
    text-align: left;
    color: #716267;
    font-size: .8rem;
    text-wrap: pretty;
  }
  .profile-card{
    border-radius: 10px;
    border-color: transparent;
    background: #f2f2f2;
  }
  .bmp-billboard{
    background: #d3b17d;
    border: transparent;
    border-radius: 13px;
    margin-bottom: 20px;
  }
  .bmp-card{
    min-height: fit-content;
    background-color: #efefef !important;
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 10px;
  }
  .bmp-card header{
    text-align: left;
    font-family: 'Playfair Display';
  }
  .bmp-card main{
    color: #716267;
    font-size: .8rem;
    text-wrap: pretty;
    padding: 10px;
    text-align: left;
    font-family: 'Urbanist';
  }

  .bmp-desc{
    line-height: 1.5 !important;
  }
  .bmp-btn{
    font-size: .75rem;
    font-weight: 400;
    border: 0px;
    background-color: #fff;
    color: rgb(26, 25, 25);
    width: auto;
    padding-inline: 10px 15px;
    border-radius: 50px;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  .bmp-hr{
    background-color: rgb(199, 199, 199);
    margin-block: 18px 10px;
  }
  .selected-item-row{
    max-height: 30vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .selected-item-outer{
    background-color: #e6c9a2;
    border-radius: 10px;
    padding: 12px;
  }
  .selected-item-inner{
    font-weight: 500;
    overflow: hidden;
  }
  .selected-item-title{
    text-wrap: balance;
    text-align: left;
    color: black;
    font-family: 'Playfair Display', serif;
  }
  .selected-item-delete-btn{
    color: currentColor;
    background-color: transparent;
    border: none;
    color: black;
  }
  .gap-3{
    gap: 1rem !important;
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  
  .skeleton-heading {
    width: 95%;
    height: 1rem;
    margin-bottom: 2rem;
    border-radius: 0.25rem;
  }
  .skeleton-desc1 {
    width: 95%;
    height: 0.3rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
  .skeleton-desc2 {
    width: 50%;
    height: 0.3rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
  .skeleton-btn {
    width: 30%;
    height: 1.5rem;
    margin-top: 2rem;

    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }

  
  .skeleton-text__body {
    width: 75%;
  }
  
  .skeleton-footer {
    width: 30%;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 90%;
    max-width: 600px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    margin: 0;
  }
  
  .btn-close {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .scrolable-row{
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
  }
  ol.gradient-list > li {
    background: #f2f1f1;;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 0.5rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
    width: 90%;
  }
  /* Custom scrollbar for WebKit browsers */
  .scrolable-row::-webkit-scrollbar {
    width: 4px;  /* Very small width for the scrollbar */
  }

  .scrolable-row::-webkit-scrollbar-thumb {
    background: #888;  /* Color of the scrollbar thumb */
    border-radius: 10px;  /* Rounded scrollbar thumb */
  }

  .scrolable-row::-webkit-scrollbar-thumb:hover {
    background: #555;  /* Color of the scrollbar thumb on hover */
  }

  .scrolable-row::-webkit-scrollbar-track {
    background: #f1f1f1;  /* Color of the scrollbar track */
  }
  .bmp-result-bio{
    text-align: left;
    font-size: 12px;
    color: #c17c4c;
    font-family: 'Playfair Display';
  }
  .bmp-result-card{
      font-family: 'Space Mono', monospace; 
      display: flex;
      flex-direction: column;
      padding: 32px;
      margin: 60px auto;
      border: 1px solid #eee;
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
    
  }
  .bmp-result-heading{
    text-align: left;
    font-family: 'Playfair Display';
  }
  .bmp-badge{
    font-family: 'Playfair Display';
  }
  .bmp-result-ol { padding-left: 50px;text-align: left; }
  .bmp-result-li { 
    color: #4F4F4F; 
    padding-left: 16px;
    margin-top: 24px;
    position: relative;
    font-size: 16px;
    line-height: 20px;
  
    &:before {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute; 

    }
  }
  strong { color: #292929; }

  /* ol.alternating-colors { 
    li:nth-child(odd):before { border-color: #cfb70a; }
    li:nth-child(even):before { border-color: #2b2c2d; }
  } */
  .bg-custom{
    background-color: rgb(217 215 215) !important;
    color: #c17c4c;
  }

  