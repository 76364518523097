.pre-message-box{
    /* border: 1px solid #ccc9c9; */
    /* padding: 30px; */
    margin-top: 20px;
    /* font-family: "Metropolis" !important; */
    font-weight: 300 !important;
}
.pre-message-box p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.pre-message-box img{
    width: 30px;
}