
  
  .skeleton-block {
    background-color: #e0e0e0;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }

  .skeleton-block::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: skeleton 1s infinite;
  }
  
 
  @keyframes skeleton {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  