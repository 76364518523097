.bg-filter-mbl{
    background-color: #EFEFEF;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: none;
    z-index: 111;
    margin-top: 30px;
}

@media (max-width: 991px){
    .bg-filter-mbl{
        display: block;
    }
}

.bg-main{
    background-color: #202f32;
}

.sticky-btn{
    padding: 12px 10px;
}

.down-arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #202f32;
}
.bg-gray-inactive{
    background-color: #e5e5e5;
    cursor: not-allowed;
}

.bg-active{
    background-color: #e2b9b3;
}
.bg-white{
    background-color: #fff;
    color: #202f32;
}
