.weight-loss-calculator {
    /* max-width: 500px; */
    max-width: 100%;
    margin: 0;
}

.weight-loss-calculator h2 {
    text-align: center;
    color: #202f32;
    margin-bottom: 30px;
}

.calculator-card {
    background: #fff;
    border-radius: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}
.weightloss-wraper .nav-tabs .nav-link,
.weightloss-wraper .tab-pane p{
    color: #202f32;
}

.weight-input-section {
    padding: 30px;
    text-align: center;
}

.weight-input-section label {
    display: block;
    margin-bottom: 20px;
    color: #202f32;
    font-size: 24px;
}

.weight-display {
    margin: 20px 0;
    font-size: 2.5rem;
    font-weight: bold;
    color: #202f32;
}

.weight-unit {
    font-size: 1.5rem;
    margin-left: 5px;
    color: #666;
}

.weight-slider {
    width: 100%;
    height: 6px;
    -webkit-appearance: none;
    background: linear-gradient(to right, #357550 0%, #202f32 100%);
    border-radius: 3px;
    outline: none;
    margin: 20px 0;
}

.weight-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 2px solid #202f32;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.unit-toggle {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #202f32;
    padding: 0;
    margin: 40px auto 0;
    width: 120px;
    height: 36px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.unit-toggle-slider {
    position: absolute;
    width: 50%;
    height: 100%;
    background: #202f32;
    transition: transform 0.3s ease;
    transform: translateX(0%);
}

.unit-toggle[data-kg="false"] .unit-toggle-slider {
    transform: translateX(100%);
}

.unit-toggle-options {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 1;
}

.unit-toggle-option {
    flex: 1;
    text-align: center;
    color: #202f32;
    font-size: 14px;
    font-family: 'metropillthin' !important;
    transition: color 0.3s ease;
    padding: 8px 0;
}

.unit-toggle[data-kg="true"] .unit-toggle-option:first-child,
.unit-toggle[data-kg="false"] .unit-toggle-option:last-child {
    color: #fff;
}

.potential-loss-section {
    background: #357550 !important;
    color: white;
    padding: 30px;
    text-align: center;
}

.potential-loss-section p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
}

.loss-amount {
    margin: 15px 0;
}

.loss-number {
    font-size: 3rem;
    font-weight: bold;
}

.loss-unit {
    font-size: 2rem;
    margin-left: 5px;
}

.timeframe {
    opacity: 0.9;
} 