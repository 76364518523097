.menu-list{
    list-style: none;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.menu-list li {
    padding: 1.5rem 1.1111111111rem;
}

.menu-list li .nav-item{
    text-decoration: none;
    color: #202f32;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 12px;
}

.menu-list li .nav-item:hover{
    color: #357550 !important;
}

.logo{
    height: 90px;
}

.logo img{
    max-height: 100px;
    height: 90px;
    position: relative;
    width: auto;
}


.menu-list .dropdown-parent:hover .droponeshow {
    display: block;
}



.droponeshow{
    display: none;
    width: 15rem !important;
    padding: 1.3888888889rem 0;
    margin: 0;
    position: absolute;
    z-index: 10000;
    text-align: left;
    background-color: #f0f0f0;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    left: calc(50% - 6.25rem);
    top: 4em;
    animation-fill-mode: forwards;
    animation-name: dropdownScaleUp;
    background-color: #202f32;
    list-style: none;
}



.droptwoshow{
    right: calc(50% - 18.5rem) !important; 
    display: none;
    width: 13rem !important;
    padding: 1.3888888889rem 1.6666666667rem;
    margin: 0;
    position: absolute;
    z-index: 10000;
    text-align: left;
    background-color: #f0f0f0;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    top: -4em;
    animation-fill-mode: forwards;
    animation-name: dropdownScaleUp;
    background-color: #202f32;
    list-style: none;
}

.menu-list .dropdown-parenttwo:hover .droptwoshow {
    display: block;
}

.droptwoshow li{
    padding: 0 !important;
}

.droptwoshow:before{
    content: none !important;
}


.dropdown-cust:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 50%;
    width: 7px;
    height: 7px;
    margin: -4px 0 0 -7px;
    transform: rotate(45deg);
    background-color: #202f32;
}

.dropdown-cust .nav-li{
    padding: 0 !important;
    padding-left: 1.6666666667rem !important;
    padding-right: 1.6666666667rem !important;
}

.dropdown-cust li .nav-itemd {
    font-size: 14px;
    padding: 0 0.5rem;
    margin: 0.5rem 0;
    line-height: 1.7rem;
    font-weight: normal;
    color: #949494 !important;
    display: block;
    text-transform: capitalize;
    letter-spacing: 0.10em;
    text-decoration: none;
    width: 100%;
    position: relative;
}

.dropdown-cust li .nav-itemd:hover {
    color: #ffffff !important;
}

.dropdown-cust li .plus::before {
    content: "+";
    position: absolute;
    font-size: 13px;
    top: 0;
    left: -3px;
}

@keyframes dropdownScaleUp {
    0% {
        transform: scaleY(.1);
        transform-origin: 50% 0%;
        opacity: 0;
    }

    100% {
        transform: scaleY(1);
        transform-origin: 50% 0%;
        opacity: 1;
    }
}

.sp-cover{
    cursor: pointer;
}

.sp-cover .sp{
    height: 1px;
    width: 22px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.sp-cover .sp,
.sp-cover .sp::before,
.sp-cover .sp::after{
    background-color: #202f32;
}

.sp-cover .sp::before{
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    display: inline-block;
    height: 1px;
    width: 22px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

}

.sp-cover .sp::after{
    content: '';
    position: absolute;
    bottom: 8px;
    left: 0;
    display: inline-block;
    height: 1px;
    width: 22px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sp-cover:hover .sp::before{
    webkit-transform: matrix(0.5, -0.3, 0, 1, 5, -4);
    -ms-transform: matrix(0.5, -0.3, 0, 1, 5, -4);
    transform: matrix(0.5, -0.3, 0, 1, 5, -4);
    height: 1px;
}

.sp-cover:hover .sp::after{
    -webkit-transform: matrix(0.5, 0.3, 0, 1, 5, 4);
    -ms-transform: matrix(0.5, 0.3, 0, 1, 5, 4);
    transform: matrix(0.5, 0.3, 0, 1, 5, 4);
    height: 1px;
}

.sp-cover:hover .sp{
    height: 1px;
}

.mbl-icon-show{
    display: none;
}

.cart-nav-1{
    position: relative;
}

.counterCart{
    position: absolute;
    top: -1px;
    left: 17px;
    width: 14px;
    height: 14px;
    background-color: #E2B9B3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s all;
}

.counterCart span{
    margin-top: -3px;
    color: #fff;
}

.cart-nav-1:hover .counterCart{
    background-color: #357550 !important;
}


@media (max-width: 1200px){
    .mbl-icon-show{
        display: block;
    }
    .menu-list{
        display: none !important;
    }
    .mbl-flex{
        justify-content: space-between;
        width: 97%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .navbar-fixed {
        padding: 0 15px !important;
    }
}

@media (max-width: 480px){
    .w-28rems{
        width: 16rem !important;
    }
}

.navbar-fixed {
    position: fixed;
    top: -100%;
    left: 0;
    margin-top: 0px !important;
    width: 100%;
    background-color: white;
    z-index: 991000;
    padding: 0 94px;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
  
.navbar-fixed.active{
    top: 0px;
    opacity: 1;
    visibility: visible;
}

.navbar-fixed .logo img{
    height: 60px !important;
    margin-top: 10px;
}