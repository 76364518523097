.bg-banner2{
    width: 100%;
    margin-top: 100px;
}

.w-80s{
    width: calc(100% - 300px);
    min-width: 0;
}
@media (max-width: 991px){
    .w-80s{
        width: 100%;
    }
}
.mbs-100{
    margin-bottom: 100px;
}

.conslut-loading {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    display: inline-block;
    width: 48px;
    height: 48px;
    color: #e2b9b3;
  }
    .conslut-loading:before,
    .conslut-loading:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s spin linear infinite;
    }
    .conslut-loading:after {
      color: #f3aa9f;
      transform: rotateY(70deg);
      animation-delay: .4s;
    }
  
  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
  
  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  
  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }
  