.closeicons{
    padding: 0px;
    background: transparent;
    border: 0px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.closeicons span{
    display: block;
    transition: .5s all;
}

.closeicons:hover span{
    transform: rotate(-180deg);
    color: #e2b9b3;
}

.closeicons span{
    font-size: 26px;
    color: #fff;
}


.logo-mbl{
    margin-top: 30px;
    margin-bottom: 50px;
}

.ant-drawer-header{
    display: none !important;
}

.bg-drawer{
    background-color: #3c3c3c !important;
}


.w-28rems{
    width: 23rem;
    margin: 0 auto;
}

.ul-menus{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.ul-menus li a{
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8em;
    letter-spacing: 0.15em;
    transform: .5s all;
    text-decoration: none;
}

.ul-menus li a:hover{
    color: #e2b9b3;
}

.ul-menuinner{
    list-style: none;
    padding-left: 0;
}

.ul-menuinner .inner-navlink{
    font-size: 16px !important;
    line-height: 1.8em;
    letter-spacing: 0.15em;
    transition: .5s all;
}

.ul-menuinner .innermost-link{
    font-size: 15px !important;
    line-height: 1.8em;
    letter-spacing: 0.15em;
    transition: .5s all;
}

.cust-accords .accordion-button{
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5em;
    letter-spacing: 0.15em;
    box-shadow: none !important;
}

.cust-accords .accordion-button:hover{
    color: #e2b9b3;
}

.hover-accord:hover a{
    color: #e2b9b3;
}

.hover-accord .pink-ic,
.hover-accord .minus-w-ic,
.hover-accord .minus-p-ic{
    display: none;
}

.hover-accord:hover .white-ic{
    display: none;
}

.hover-accord:hover .pink-ic{
    display: block !important;
}


.cust-accords .two{
    display: none;
}

.cust-accords .accordion-button{
    position: relative;
}

.cust-accords .accordion-button::after{
    content: '-' !important;
    position: absolute;
    background-image: none !important;
    right: 0px;
    top: -2px;
    display: none;
    width: 0;
    display: none !important;
}

.cust-accords .accordion-button:hover::after{
    background-color: transparent;
}

.cust-accords .accordion-button::before{
    content: '+' !important;
    position: absolute;
    background-image: none !important;
    right: 0px;
    width: 0;
    display: none !important;
}

.cust-accords .accordion-button:hover::before{
    background-color: transparent !important;
}

.cust-accords .accordion-button:not(.collapsed) .minus-w-ic{
    display: block !important;
}

.cust-accords .accordion-button:not(.collapsed):hover .minus-p-ic{
    display: block !important;
}

.cust-accords .accordion-button:not(.collapsed):hover .minus-w-ic{
    display: none !important;
}

.cust-accords .accordion-button:not(.collapsed) .plus-div{
    background-image: none !important;
    transform: none !important;
    display: none !important;
}




.custom-search{
    padding: 1.108rem 1.2rem 1.108rem 1.2rem !important;
    background-color: rgba(32, 47, 50, 0);
    border:1px solid #464646;
}

.custom-search input{
    color: #b7b7b7 !important;
}

.custom-search input:focus{
    box-shadow: none;
}

.custom-search input::placeholder{
    color: #b7b7b7 !important; 
}

.custom-search span{
    color: #fff;
    font-size: 20px;
}

.custom-search span:hover{
    color: #e2b9b3;
}

.social-icons{
    font-size: 24px;
    color: #fff;
    transition: .5s all;
    
}

.social-icons:hover{
    color: #e2b9b3;
}