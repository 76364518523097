.custom-input input,
.custom-input textarea{
    border: 1px solid #000;
    border-radius: 0px;
    padding: 16px 20px !important;
    font-size: 16px !important;
    color: #434872 !important;
    font-family: 'metropill' !important;
    font-weight: normal !important;
    background-color: transparent;
}

.custom-input textarea{
    resize: none;
}

.custom-input input::placeholder{
    color: #434872 !important;
}

.custom-input textarea::placeholder{
    color: #434872 !important;
}

/* dark bg input css */

.custom-inputdark input,
.custom-inputdark textarea{
    border: 2px solid #464646 !important;
    border-radius: 0px;
    padding: 1.4rem 2rem !important;
    font-size: 16px !important;
    color: #b7b7b7 !important;
    font-family: 'metropillthin' !important;
    font-weight: normal !important;
    background-color: transparent;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
    background-color: rgba(32,47,50,0) !important;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    border-color: #cc0000 !important;
}

.custom-inputdark textarea{
    resize: none;
}

.custom-inputdark input::placeholder{
    color: #b7b7b7 !important;
}

.custom-inputdark textarea::placeholder{
    color: #b7b7b7 !important;
}

