html {
    font-size: 18px;
}

.weightloss-wraper .weight-loss-page {
    /* background: #f2f0f1; */
    padding: 100px 0 50px;
}

.weightloss-wraper .breadcrumnb-img-bg-weightloss {
    background-image: url('../../../public/assets/images/banner-weight-loss.jpg');
    background-image: url('../../../public/assets/images/wl-banner-genral.png');
    /* background: #f2f0f1; */
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 55px;
    padding-bottom: 80px;
    width: 100%;
    height: 100%;
}

.breadcrumnb-img-bg.breadcrumnb-img-bg-new {
    background-image: url('../../../public/assets/images/banner/new-banner-sample.jpg');
    background-position: center;
}

.video-wrap video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.weightloss-wraper .weight-loss-page .btn-primary {
    font-family: "Metropolis", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    position: relative;
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1.4166666667rem 2.2222222222rem;
    letter-spacing: 0.2em;
    height: auto;
    border-width: 0 !important;
    max-width: 100%;
    z-index: 0;
    background: linear-gradient(to right, #e2b9b3 50%, #202f32 50%) no-repeat scroll right bottom / 210% 100% #202f32 !important;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    color: #fff;
    border-radius: 0;
    outline: none;
    overflow: hidden;
    font-family: 'metropill' !important;
    margin-bottom: 70px;
}

.weightloss-wraper .weight-loss-page .btn-primary:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.5px;
    right: -0.5px;
    bottom: -1px;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: -webkit-transform 400ms ease-in-out;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    background: inherit;
    background-position: 0 0;
    border: none;
    border-radius: 0;
}

.weightloss-wraper .weight-loss-page .btn-primary:focus:after,
.weightloss-wraper .weight-loss-page .btn-primary:hover:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background: inherit;
    background-position: 0 0;
}

.weightloss-wraper .weight-loss-page h1 {
    color: #323333;
    /* font-size: 90px;
    line-height: 90px; */
    font-weight: 400;
    font-size: 5rem;
    line-height: 1em;
}

.weightloss-wraper .weight-loss-page h1 span {
    color: #e2b9b3;
}

.weightloss-wraper .weight-loss-page p {
    /* font-size: 24px;
    line-height: 36px;
    font-weight: 400; */
    color: rgba(148, 148, 148, 1);
    font-family: 'metropillthin' !important;
    font-size: 1.34rem;
    font-weight: 300;
    line-height: 1.5em;
}

.weightloss-wraper .weight-loss-page img {
    max-height: 500px;
}

.weightloss-wraper .stats-banner {
    background-color: #E2B9B3;
    padding: 35px 0;
}

.weightloss-wraper .stats-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.weightloss-wraper .stat-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.weightloss-wraper .stat-number {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    font-family: "Playfair Display", serif !important;
    margin-bottom: 0;
}

.weightloss-wraper .stat-number .unit {
    font-size: 24px;
    margin-left: 2px;
}

.weightloss-wraper .stat-text {
    font-size: 16px;
    color: #fff;
    max-width: 200px;
    font-family: 'metropill' !important;
    text-transform: capitalize;
    line-height: 22px;
    margin-bottom: 0;
}

.weightloss-wraper .graph-wrap {
    background-color: #fff;
    padding: 25px;
}

/* new faqs */
html body .weightloss-wraper .faq-section {
    background-color: #F2F2F2;
    color: #202f32;
}

html body .weightloss-wraper .faq-title,
html body .weightloss-wraper .accordion-title,
html body .weightloss-wraper .accordion-content p,
html body .weightloss-wraper .accordion-icon {
    color: #202f32;
}

html body .weightloss-wraper .accordion-icon {
    font-weight: 700;
}

html body .weightloss-wraper .active span.accordion-title,
html body .weightloss-wraper .active .accordion-icon {
    color: #E2B9B3;
}

html body .weightloss-wraper .accordion-header {
    background-color: #F2F2F2;
}

html body .weightloss-wraper .pricing-accordion .accordion-header {
    background: #202f32;
}

html body .weightloss-wraper .accordion-content {
    padding: 0 15px;
}

.weightloss-wraper .bmi-section-main .bmi-switch.ant-switch * {
    color: #fff !important;
}

/* new faqs */
.form_wrap .custom-inputdark input,
.form_wrap .custom-inputdark textarea {
    color: #202F32 !important;
}

.weightloss-wraper .unique-objects-section {
    position: relative;
}

.weightloss-wraper .unique-objects-section>.unique-objects-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    padding-top: 0;
}

.weightloss-wraper .unique-objects-section>.unique-objects-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

/* Responsive styles */
@media (max-width: 768px) {
    .weightloss-wraper .stats-wrapper {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .weightloss-wraper .stat-item {
        flex-direction: column;
        gap: 5px;
    }

    .weightloss-wraper .stat-text {
        max-width: 100%;
    }
}


@media (max-width: 991px) {

    .unique-objects-image {
        display: none !important;
    }

    .weightloss-wraper .breadcrumnb-img-bg {
        background: #f2f1ef !important;
        /* min-height: 60vh !important; */
        padding-bottom: 0px !important;
        padding-top: 20px !important;
    }

    .weightloss-wraper .breadcrumb-link {
        font-size: 12px !important;
    }

    .weightloss-wraper .w-font {
        width: 100% !important;
    }
}

.weightloss-wraper .testimonials-section {
    background-color: #fff;
    color: rgba(50, 51, 51, 1);
    overflow: hidden;
}

.weightloss-wraper .testimonials-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.weightloss-wraper .testimonials-header h2 {
    color: rgba(50, 51, 51, 1);
    /* font-size: 34px;
    line-height: 42px; */
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    margin: 0;
    line-height: 1.2352em;
    font-size: 1.88889rem;
}

.testi-wrap-content-inner {
    padding: 65px 0 150px;
}

.weightloss-wraper .testimonial-card {
    padding: 50px;
    margin: 15px 20px;
    border-radius: 0;
    position: relative;
    border: 1px solid;
    position: relative;
    font-weight: 300;
    color: #949494;
    border-color: #E5E5E5;
}

.weightloss-wraper .testimonial-card:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -13px;
    left: -1px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    border-left: 1px solid;
    background-color: rgba(50, 51, 51, 1);
    background-color: #fff;
    border-color: #464646;
    display: none;
}

.weightloss-wraper .testimonial-card:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -17px;
    left: 6px;
    width: 1px;
    height: 20px;
    line-height: 20px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 1px solid;
    border-color: #464646;
    display: none;
}

.weightloss-wraper .quote-mark {
    font-size: 100px;
    position: absolute;
    top: 20px;
    left: 20px;
    /* opacity: 0.3; */
    font-family: Georgia, serif;
    color: #e2b9b3;
    position: absolute;
    z-index: 1;
    top: -15px;
    left: 2.22222rem;
}

.weightloss-wraper .quote-mark img {
    max-width: 40px;
}

.weightloss-wraper .testimonial-text {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .testimonial-author {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.weightloss-wraper .author-name {
    font-size: 14px;
    line-height: 21px;
    font-family: 'metropillthin' !important;
    color: #202F32;
}

.weightloss-wraper .author-location {
    font-size: 14px;
    line-height: 21px;
    font-family: 'metropillthin' !important;
    color: #202F32;
}

/* Custom Slider Styles */
.testimonials-slider {
    padding-left: 5%;
    padding-right: 5%;
}

html body .weightloss-wraper .slick-dots li button:before {
    color: #e2b9b3;
}

html body .weightloss-wraper .slick-dots li.slick-active button:before {
    color: #fff;
}

.weightloss-wraper .background-image-wrap {
    background-image: url('../../../public/assets/images/banner-weight-loss.jpg');
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.weightloss-wraper .video-wrap {
    background-image: url('../../../public/assets/images/banner-weight-loss.jpg');
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    min-height: 500px;
    border-radius: 20px;
}

.video-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to right, rgb(92 62 47 / 50%), rgb(97 66 51 / 10%));
    left: 0;
    /* opacity: 0; */
}

.weightloss-wraper .btn-video {
    background-color: #e2b9b3;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #e2b9b3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.weightloss-wraper .btn-video:hover {
    background-color: transparent;
    width: 105px;
    height: 105px;
}

/* Responsive Styles */



@media (max-width: 768px) {
    .weightloss-wraper .testimonials-header h2 {
        /* font-size: 32px; */
    }

    .weightloss-wraper .testimonial-card {
        padding: 30px;
    }
}

.weightloss-wraper .weight-lose-video-section {
    position: relative;
}

.weightloss-wraper .video-wrap {
    /* background: url('path-to-your-video-thumbnail.jpg') no-repeat center center; */
    background-size: cover;
    height: 500px;
    border-radius: 10px;
    position: relative;
}

.weightloss-wraper .btn-video {
    cursor: pointer;
    transition: transform 0.3s ease;
    /* width: 80px;
    height: 80px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    backdrop-filter: blur(5px);
}

.weightloss-wraper .btn-video:hover {
    transform: scale(1.1);
}

.weightloss-wraper .btn-video img {
    /* width: 30px;
    height: 30px; */
}

/* Modal Styles */
.weightloss-wraper .video-modal {
    position: relative;
}

.weightloss-wraper .close-modal {
    position: absolute;
    right: -40px;
    top: -40px;
    background: none;
    border: none;
    color: white;
    font-size: 36px;
    cursor: pointer;
    padding: 5px;
    line-height: 1;
    z-index: 1001;
}

.weightloss-wraper .video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.weightloss-wraper .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.weightloss-wraper .team-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 0;
}

.weightloss-wraper .team-img img {
    width: 100%;
    height: auto;
    display: block;
}

.weightloss-wraper .team-content {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    transition: all 0.3s ease;
    opacity: 0;
}

.weightloss-wraper .team-wrap:hover .team-content {
    opacity: 1;
}

.weightloss-wraper .team-content h3 {
    margin-bottom: 15px;
    color: #202f32;
}

.weightloss-wraper .team-content ul {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    padding: 30px 0 0;
}

.weightloss-wraper .team-content ul li a {
    margin: 0.25rem 0 0em 0;
    color: #202f32;
    background-color: #ffffff;
    border-color: #202f32;
    width: 4.4444444444rem;
    height: 4.4444444444rem;
    line-height: 4.4444444444rem;
    text-align: center;
    border-radius: 0;
    border: 1px solid;
    border-right: 0;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.weightloss-wraper .team-content ul li:nth-last-child(1) a {
    border-right: 1px solid;
}

.weightloss-wraper .team-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: all 0.3s ease;
}

.weightloss-wraper .team-wrap:hover .team-layer {
    background-color: #f2f1ef;
    opacity: 0.8;
    pointer-events: auto;
}

.weightloss-wraper .social-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 0;
}

.weightloss-wraper .social-links a {
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transition: all 0.3s ease;
}

.weightloss-wraper .social-links a:hover {
    color: #ffffff;
    background-color: #202f32;
    border-color: #202f32;
}

.weightloss-wraper .social-links svg {
    width: 20px;
    height: 20px;
}

.weightloss-wraper section.weight-lose-video-section h2 {
    font-size: 42px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .weightloss-wraper .video-wrap {
        height: 300px;
    }

    .weightloss-wraper .btn-video {
        width: 60px;
        height: 60px;
    }

    .weightloss-wraper .btn-video img {
        width: 24px;
        height: 24px;
    }

    .weightloss-wraper .close-modal {
        right: 0;
        top: -50px;
    }
}

.weightloss-wraper .faq-section {
    background-color: #202F32;
    padding: 100px 0;
    color: #fff;
}

.weightloss-wraper .faq-title {
    text-align: center;
    /* font-size: 34px;
    line-height: 42px; */
    margin-bottom: 50px;
    color: #fff;
    line-height: 1.2352em;
    font-size: 1.88889rem;
}

.weightloss-wraper .accordion-container {
    /* max-width: 900px;
    margin: 0 auto; */
}

.weightloss-wraper .accordion-item {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.weightloss-wraper .accordion-item.active .accordion-header {
    color: #E2B9B3;
}

.weightloss-wraper .accordion-header {
    width: 100%;
    background: #202f32;
    border: none;
    padding: 25px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #fff;
    text-align: left;
    line-height: 18px;
    transition: 0.3s;
}

.weightloss-wraper .pricing-accordion .accordion-header:hover {
    background: #e2b9b3;
}

.pricing-table .pricing-row,
.pricing-table .pricing-note {
    text-align: left;
    padding: 15px 15px;
    color: rgb(148, 148, 148);
    font-size: 18px;
    line-height: 27px;
}

.pricing-row.header div {
    color: rgb(148, 148, 148);
    font-size: 18px;
    line-height: 27px;
    font-family: 'metropill' !important;
    font-weight: 700;
}

.service-col,
.price-col {
    max-width: 50%;
    width: 100%;
    text-align: left !important;
}

.pricing-row:nth-last-child(1) {
    border: none;
}

.pricing-row:nth-child(even) {
    background-color: #f2f1ef;
    border-color: #f2f1ef;
}

.pricing-table * {
    font-family: 'metropillthin' !important;
    color: rgb(148, 148, 148);
}

.weightloss-wraper .accordion-title {
    font-size: 18px;
    font-weight: 500;
    padding-right: 40px;
}

.weightloss-wraper .accordion-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

/* .weightloss-wraper .accordion-item.active .accordion-icon {
    transform: rotate(45deg);
} */

.weightloss-wraper .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    transition: 0.3s !important;
    /* padding: 0 20px; */
}

.weightloss-wraper .accordion-item.active .accordion-content {
    max-height: 500px;
    transition: 0.3s !important;
    /* padding-bottom: 25px; */
}

.weightloss-wraper .accordion-content p {
    margin: 0 0 15px;
    line-height: 1.6;
    opacity: 0.8;
    font-family: 'metropillthin' !important;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .weightloss-wraper .faq-section {
        padding: 60px 0;
    }

    .weightloss-wraper .faq-title {
        /* font-size: 32px;
        margin-bottom: 40px; */
    }

    .weightloss-wraper .accordion-title {
        font-size: 16px;
    }

    .weightloss-wraper .accordion-header {
        padding: 20px 0;
    }
}

.weightloss-wraper .how-it-works-section {
    padding: 90px 0;
    background-color: #f1f0ee;
    color: #202f32;
}

.weightloss-wraper .how-it-works-section h2 {
    color: #fff;
    color: #202f32;
    /* font-size: 42px; */
    margin-bottom: 80px;
    line-height: 1.2352em;
    font-size: 1.88889rem;
}

.weightloss-wraper .bmi-section-main h2 {
    color: #fff;
    color: #202f32;
    /* font-size: 42px; */
    margin-bottom: 40px;
    line-height: 1.2352em;
    font-size: 1.88889rem;
}

.weightloss-wraper .nav-tabs {
    border: none;
    gap: 60px;
    margin-bottom: 30px;
    justify-content: center;
}

.weightloss-wraper .nav-tabs .nav-link span {
    display: block;
    font-family: 'metropill' !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weightloss-wraper .nav-tabs .nav-link div {
    font-family: 'metropill' !important;
}

.weightloss-wraper .nav-tabs .nav-link span:after {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 0px;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
}

.weightloss-wraper .nav-tabs .nav-link.active span:after {
    content: "\f107";
}

html body .weightloss-wraper .tab-pane p {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
}

.weightloss-wraper .nav-tabs .nav-item {
    position: relative;
    max-width: 23%
}

.weightloss-wraper .nav-tabs .nav-item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -15px;
    width: 30px;
    height: 1px;
    background-color: rgba(226, 185, 179, 0.3);
    transform: translateX(100%);
}

.weightloss-wraper .nav-tabs .nav-link {
    border: none;
    background: none;
    color: #fff;
    padding: 15px 0;
    position: relative;
    text-align: center;
    transition: all 0.3s ease;
    font-family: 'metropill' !important;
    cursor: pointer;
    font-size: 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.weightloss-wraper .nav-tabs .nav-link .step-number {
    display: block;
    color: #E2B9B3;
    font-size: 24px;
    margin-bottom: 10px;
}

.weightloss-wraper .nav-tabs .nav-link.active {
    background: none;
    color: #E2B9B3;
    border: none;
}

.weightloss-wraper .nav-tabs .nav-link:hover {
    border: none;
    color: #E2B9B3;
}

.weightloss-wraper .tab-content {
    color: rgba(255, 255, 255, 0.8);
}

.weightloss-wraper .tab-content h3 {
    color: #fff;
}

.weightloss-wraper .tab-pane p {
    font-size: 22px;
    line-height: 1.6;
    max-width: 600px;
    margin: 0;
    font-family: 'metropillthin' !important;
    text-align: left;
}

/* Responsive styles */
@media (max-width: 768px) {
    .weightloss-wraper .nav-tabs {
        flex-direction: column;
        gap: 15px;
    }

    .weightloss-wraper .nav-tabs .nav-item:not(:last-child)::after {
        display: none;
    }

    .weightloss-wraper .nav-tabs .nav-link {
        padding: 10px 20px;
    }

    .weightloss-wraper .nav-tabs .nav-link .step-number {
        font-size: 20px;
        margin-bottom: 5px;
    }
}

.weightloss-wraper .how-it-works-content {
    padding-left: 50px;
}

.weightloss-wraper .how-it-works-content h2 {
    font-size: 48px;
    margin-bottom: 50px;
    color: #E6B9B0;
    /* Pink color from your design */
    font-weight: 300;
}

.weightloss-wraper .steps-container {
    display: flex;
    /* flex-direction: column; */
    gap: 40px;
}

.weightloss-wraper .step-item {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.weightloss-wraper .step-number {
    font-size: 24px;
    font-weight: 500;
    color: #E6B9B0;
    min-width: 45px;
}

.weightloss-wraper .step-content h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 400;
}

.weightloss-wraper .step-content p {
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}

.weightloss-wraper .how-it-works-image {
    background-color: #243436;
    /* Slightly lighter than background for placeholder */
    height: 100%;
    min-height: 400px;
    border-radius: 8px;
    overflow: hidden;
}

/* Add a line connecting the steps */
.weightloss-wraper .step-item {
    position: relative;
}

.weightloss-wraper .how-it-works-content .step-item {
    display: flex;
    flex-direction: column;
}

.weightloss-wraper .step-item:not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: rgba(230, 185, 176, 0.3);
    right: 0;
    width: 50px;
    top: 50%;
    transform: translateY(-50%);
}

/* Responsive styles */
@media (max-width: 991px) {
    .weightloss-wraper .how-it-works-content {
        padding-left: 0;
        margin-top: 50px;
    }

    .weightloss-wraper .how-it-works-content h2 {
        font-size: 36px;
        margin-bottom: 40px;
    }

    .weightloss-wraper .how-it-works-image {
        min-height: 300px;
    }
}

@media (max-width: 768px) {
    .weightloss-wraper .how-it-works-section {
        /* padding: 60px 0; */
    }

    .weightloss-wraper .step-content h3 {
        font-size: 20px;
    }

    .weightloss-wraper .step-number {
        font-size: 20px;
    }
}

/* btn primary */

.weightloss-wraper .btn-appointment.btn-primary {
    /* font-family: "Metropolis", sans-serif; */
    font-family: 'metropill' !important;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    position: relative;
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1.4166666667rem 2.2222222222rem;
    letter-spacing: 0.2em;
    height: auto;
    border-width: 0 !important;
    max-width: 100%;
    z-index: 0;
    /* background: linear-gradient(to right, #e2b9b3 50%, #202f32 50%) no-repeat scroll right bottom / 210% 100% #202f32 !important; */
    background: linear-gradient(to right, #fff 50%, #e2b9b3 50%) no-repeat scroll right bottom / 210% 100% #e2b9b3 !important;
    /* border: 1px solid #e2b9b3; */
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    color: #000;
    border-radius: 0;
    outline: none;
    overflow: hidden;
    margin-bottom: 70px;
    min-height: 66px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-green {
    color: #357550 !important;
}

.weightloss-wraper .btn-appointment.btn-primary:hover {
    color: #202f32;
}

.weightloss-wraper .btn-appointment.btn-primary:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.5px;
    right: -0.5px;
    bottom: -1px;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: -webkit-transform 400ms ease-in-out;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    background: inherit;
    background-position: 0 0;
    border: none;
    border-radius: 0;
}

.weightloss-wraper .btn-appointment.btn-primary:focus:after,
.weightloss-wraper .btn-appointment.btn-primary:hover:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background: inherit;
    background-position: 0 0;
}

/* btn-priamry */

.weightloss-wraper .intro-section {
    padding: 120px 0 0;
    color: #202f32;
    background-color: #fff;
}

.weightloss-wraper .intro-section h2 {
    /* font-size: 34px;
    line-height: 42px; */
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.2352em;
    font-size: 1.88889rem;
}

.weightloss-wraper .intro-content {
    margin: 0 auto;
    margin-bottom: 30px;
}


.weightloss-wraper .intro-description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #000;
    font-family: 'metropillthin' !important;
    line-height: 1.566em;
}

.weightloss-wraper .shadow-intro {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.weightloss-wraper .intro-subtitle {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    color: #202f32;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .intro-banner {
    /* padding: 30px; */
    position: relative;
    /* height: 500px; */
    background-image: url('../../../public/assets/images/banner-weight-loss.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 0;
    overflow: hidden;
}

.weightloss-wraper .intro-banner .banner-content {
    max-width: 52rem;
    padding: 5.2222222222rem 6.8888888889rem;
}

.weightloss-wraper .intro-banner .banner-content h2 {
    font-size: 1.7777777778rem;
    line-height: 1.235em;
    font-family: 'metropillthin' !important;
    margin-bottom: 70px;
}

.weightloss-wraper .intro-banner .banner-content h2 span {
    display: block;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .intro-sec-inner {
    padding: 0;
    color: #fff;
    /* background-color: #f1f0ee; */
}

.weightloss-wraper .banner-content {
    position: relative;
    z-index: 99;
    /* position: absolute;
    left: 0;
    bottom: 0;
    padding: 60px;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    width: 100%; */
}

.weightloss-wraper .banner-content h2 {
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 10px;
    color: #fff;
}

.weightloss-wraper .banner-content h3 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.9);
}

.weightloss-wraper .btn-appointment {
    background-color: #E6B9B0;
    color: #202f32;
    border: none;
    padding: 15px 40px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.weightloss-wraper .btn-appointment:hover {
    background-color: #d9a499;
}

/* Responsive Styles */
@media (max-width: 991px) {


    .weightloss-wraper .banner-content {
        padding: 40px;
    }

    .weightloss-wraper .banner-content h2 {
        font-size: 32px;
    }

    .weightloss-wraper .banner-content h3 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .weightloss-wraper .intro-description {
        font-size: 16px;
    }

    .weightloss-wraper .intro-section {
        padding: 60px 0 0;
    }

    .weightloss-wraper .intro-banner {
        height: 400px;
    }

    .weightloss-wraper .banner-content {
        padding: 30px;
    }

    .weightloss-wraper .btn-appointment {
        padding: 12px 30px;
    }
}

.weightloss-wraper .unique-objects-section {
    background-color: #202f32;
    padding: 0;
    color: #fff;
    position: relative;
}

.weightloss-wraper .unique-objects-content {
    position: relative;
    z-index: 99999;
    padding: 150px 0;
}

.weightloss-wraper .unique-objects-content h2 {
    /* font-size: 80px;
    line-height: 80px; */
    margin-bottom: 70px;
    font-weight: 300;
    color: #fff;
    font-size: 4.44444em;
    font-weight: 400;
    font-style: normal;
    line-height: 1em;
}

.weightloss-wraper .unique-objects-content .highlight {
    color: #e2b9b3;
}

.weightloss-wraper .stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.weightloss-wraper .unique-objects-content .stat-header {
    gap: 0;
}

.weightloss-wraper .unique-objects-content .stat-number {
    color: #e2b9b3;
    margin-bottom: 10px;
    /* font-size: 34px;
    line-height: 37px; */
    font-size: 1.8888888889rem;
    line-height: 1.1em;
}

.weightloss-wraper .unique-objects-content .stat-title {
    /* font-size: 26px;
    line-height: 33px; */
    line-height: 1.25em;
    font-size: 1.4444444444rem;
    text-transform: lowercase;
    font-family: 'metropillthinner' !important;
}

.weightloss-wraper .unique-objects-content .stat-description {
    /* font-size: 18px;
    line-height: 28px; */
    line-height: 1.566em;
    font-size: 1rem;
}

.weightloss-wraper .unique-objects-shadow {
    position: absolute;
    /* background: linear-gradient(to right, #25323D, #4D5D6B, rgba(255,255,255,0.6), rgba(255,255,255,0.2)); */
    background: linear-gradient(to right, #25323D, #4D5D6B, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
}

.weightloss-wraper .stat-item {
    display: flex;
    /* flex-direction: column; */
    gap: 30px;
}

.weightloss-wraper .unique-objects-content .stat-item {
    align-items: start;
}

.weightloss-wraper .unique-objects-content .stat-item {
    flex-direction: column;
}

.weightloss-wraper .stat-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.weightloss-wraper .stat-number {
    font-size: 42px;
    font-weight: 500;
    /* color: #fff; */
    line-height: 1;
}

.weightloss-wraper .stat-number .unit {
    font-size: 32px;
    margin-left: 2px;
}

.weightloss-wraper .stat-title {
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    font-weight: 400;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .stat-description {
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 1);
    margin: 0;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .unique-objects-image {
    position: relative;
    height: 100%;
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* z-index: 99999; */
    padding-top: 25px;
}

.weightloss-wraper .unique-objects-image img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 750px;
}

.weightloss-wraper .play-button {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 80px;
    height: 80px;
    background-color: #E6B9B0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.weightloss-wraper .play-button:hover {
    transform: scale(1.1);
}

.weightloss-wraper .play-icon {
    color: #202f32;
    font-size: 24px;
    margin-left: 4px;
}

/* Responsive Styles */
@media (max-width: 991px) {
    .weightloss-wraper .unique-objects-content h2 {
        /* font-size: 48px;
        margin-bottom: 40px; */
    }

    .weightloss-wraper .unique-objects-image {
        min-height: 400px;
        margin-top: 40px;
    }
}

@media (max-width: 768px) {
    .weightloss-wraper .unique-objects-section {
        padding: 60px 0;
    }

    .weightloss-wraper .stats-grid {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .weightloss-wraper .stat-number {
        font-size: 36px;
    }

    .weightloss-wraper .stat-number .unit {
        font-size: 28px;
    }

    .weightloss-wraper .play-button {
        width: 60px;
        height: 60px;
        bottom: 20px;
        right: 20px;
    }

    .weightloss-wraper .play-icon {
        font-size: 20px;
    }
}

.weightloss-wraper .weight-loss-products {
    padding: 0px 0 120px;
    background-color: #f8f9fa;
    background-color: #fff;
}

.weightloss-wraper .product-card {
    background: #fff;
    border-radius: 0;
    padding: 20px;
    height: 100%;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    box-shadow: none;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #464646;
}

.weightloss-wraper .product-card:hover {
    transform: translateY(-5px);
}

.weightloss-wraper .product-card .btncust-pink {
    background-color: #202f32 !important;
    color: #fff !important;
    text-decoration: none !important;
}

.weightloss-wraper .discount-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #E2B9B3;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .product-image {
    text-align: center;
    margin-bottom: 30px;
}

.weightloss-wraper .product-image img {
    /* max-width: 200px; */
    max-width: 100%;
    width: 100%;
    height: auto;
}

.weightloss-wraper .product-content h3 {
    color: #202f32;
    /* font-size: 28px;
    margin-bottom: 20px; */
    /* font-size: 34px;
    line-height: 42px; */
    font-size: 1.9rem;
    line-height: 2.1rem;
    font-weight: 300;
    margin-bottom: 30px;
}

.weightloss-wraper .product-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.weightloss-wraper .tag {
    background: rgba(226, 185, 179, 0.1);
    color: #202f32;
    padding: 6px 12px;
    border-radius: 15px;
    font-size: 14px;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .tag.status {
    background: #202f32;
    color: #fff;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .product-features {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
    min-height: 130px;
}

.weightloss-wraper .product-features li {
    padding-left: 30px;
    position: relative;
    margin-bottom: 15px;
    color: #202f32;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .product-features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #E2B9B3;
    font-weight: bold;
}

.weightloss-wraper .product-pricing {
    margin-bottom: 30px;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .price-block {
    margin-bottom: 15px;
}

.weightloss-wraper .price-block .label {
    display: block;
    color: #202f32;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: 'metropillthin' !important;
    position: unset;
    transform: translate(0, 0);
}

.weightloss-wraper .price {
    font-size: 24px;
    font-weight: 600;
    color: #202f32;
    margin-right: 10px;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .original-price {
    text-decoration: line-through;
    color: #999;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .promo-code {
    font-size: 14px;
    color: #202f32;
    margin-bottom: 10px;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .promo-code strong {
    color: #E2B9B3;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .price-note {
    font-size: 12px;
    color: #666;
    font-family: 'metropillthin' !important;
}

.weightloss-wraper .btn-primary {
    background: #202f32;
    border: none;
    padding: 12px 30px;
    border-radius: 25px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease;
}

.weightloss-wraper .btn-primary:hover {
    background: #E2B9B3;
    color: #202f32;
}

.weightloss-wraper .img-wrap-graph img {
    width: 100%;
    max-height: 475px;
    object-fit: contain;

}

@media (max-width: 768px) {
    .weightloss-wraper .product-card {
        margin-bottom: 30px;
    }

    .weightloss-wraper .product-image img {
        /* max-width: 150px; */
    }
}

.weightloss-wraper .bmi-section {
    padding: 80px 0;
    background-color: #f8f9fa;
    margin-bottom: 60px;
}

.weightloss-wraper .bmi-calculator-wrapper {
    background: #fff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

/* Fade animations that Bmi.jsx expects */
.weightloss-wraper .fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.weightloss-wraper .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.weightloss-wraper .btn-primary {
    background: #202f32;
    border: none;
    padding: 12px 30px;
    border-radius: 25px;
    transition: all 0.3s ease;
}

.weightloss-wraper .btn-primary:hover {
    background: #E2B9B3;
    color: #202f32;
}

.form_wrap {
    padding: 100px 0;
}

.form_wrap h3 {
    /* font-size: 34px;
    line-height: 42px; */
    margin-bottom: 30px;
    text-transform: capitalize;
    color: rgba(50, 51, 51, 1);
    font-weight: 300;
    font-size: 1.88889rem;
    line-height: 1.2352em;
}

.form_wrap p {
    /* font-size: 18px;
    line-height: 28px; */
    font-family: 'metropillthin' !important;
    color: #000;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.566em;
}

.form_wrap p span {
    font-family: 'metropillthin' !important;
}

.form_wrap p a {
    font-family: 'metropillthin' !important;
    text-decoration: none !important;
    transition: 0.3s;
}

.form_wrap p img {
    max-width: 22px;
    margin-right: 10px;
}

.weightloss-wraper .form_wrap .parent-form {
    background: #fff;
}

.weightloss-wraper .form_wrap .right-form-cs {
    width: 100%;
    padding: 0 !important;
}

.weightloss-wraper .form_wrap form.ant-form.ant-form-vertical {
    padding: 40px 0 0 !important;
}

.weightloss-wraper .form_wrap .parent-form h4 {
    color: #202F32 !important;
    display: none !important;
}

.weightloss-wraper .form_wrap .img-left-form {
    display: none;
}

.weightloss-wraper .form_wrap .btncust-pink {
    background-color: #202f32 !important;
    color: #fff !important;
    margin: 0 auto;
}

.weightloss-wraper .form_wrap .pb-20 {
    padding-bottom: 0 !important;
}

.weightloss-wraper section.bmi-section-main {
    background: #f1f0ee;
    padding: 90px 0;
}

/* .weightloss-wraper section.bmi-section-main.img-banner-wl{
    background-size: 45% 100%;
    background-repeat: no-repeat;
} */
.weightloss-wraper .bmi-section-main .bmi-result {
    border-radius: 0;
    padding-left: 25px;
    padding-right: 25px;
}

.weightloss-wraper .bmi-section-main form.w-form {
    /* border: 1px solid #202f32; */
    background-color: #E2B9B3 !important;
    background-color: #f9f0ee !important;
    border: 1px solid #e2abab !important;
}

.weightloss-wraper .bmi-section-main form.w-form .bmi-header {
    padding-top: 25px;
}

.weightloss-wraper .bmi-section-main form.w-form .bmi-header,
.weightloss-wraper .bmi-section-main form.w-form .bmi-inputs-height,
.weightloss-wraper .bmi-section-main form.w-form .bmi-inputs-weight {
    padding-left: 25px;
    padding-right: 25px;
}

.weightloss-wraper .bmi-section-main .bmi-switch.ant-switch {
    /* background: #202f32 !important; */
}

.weightloss-wraper .bmi-section-main .bmi-switch .ant-switch-inner-checked,
.weightloss-wraper .bmi-section-main .bmi-switch .ant-switch-inner-unchecked {
    font-family: 'metropillthin' !important;

    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weightloss-wraper .bmi-section-main .segment-bar .indicator {
    opacity: 1;
}

.weightloss-wraper .bmi-section-main .segment-bar * {
    /* opacity: 0.3; */
}

.weightloss-wraper .bmi-section-main .segment-bar .segment.underweight {
    background-color: #f1f0ee;
}

.weightloss-wraper .bmi-section-main .segment-bar .segment.healthy {
    background-color: #f1f0ee;
    /* border-right: 5px solid #fff; */
}

.weightloss-wraper .bmi-section-main .segment-bar .segment.overweight {
    background-color: #357550 !important;
    /* border-right: 5px solid #fff; */
    background: #e2b9b3 !important;
    opacity: 0.5;
}

.weightloss-wraper .bmi-section-main .segment-bar .segment.obese {
    background-color: #E2B9B3 !important;
}

.weightloss-wraper .bmi-section-main section.d-flex.justify-content-end,
.weightloss-wraper .bmi-section-main .bmi-result {
    background: #fff !important;
}

.bmi-section-main section.bmi-result p,
.bmi-section-main span,
.bmi-section-main a {
    color: #202f32 !important;
}

.weightloss-wraper .bmi-section-main form.w-form {
    margin: 0;
    background: #357550;
}

.bmi-section-main section.bmi-result .success-section>p:nth-child(1) {
    color: #357550 !important;
}

.bmi-section-main section.bmi-result .error-section>p:nth-child(1) {
    color: rgba(255, 0, 0, 0.59) !important;
}

.product-card .cricle-amount {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.weightloss-wraper section.bmi-section-main .consultation-bmi-section .text-danger {
    color: rgb(137 45 54) !important;
}

.bmi-section-main .bmi-title,
.bmi-section-main .bmi-desc,
.bmi-section-main .col-4.text-start.text-dark,
.bmi-section-main .bmi-input .floating-label input,
.bmi-section-main .bmi-input .floating-label input::placeholder {
    color: #fff !important;
    color: #202f32 !important;
}

.bmi-section-main .bmi-input .floating-label input {
    border-color: #fff !important;
    border-color: #202f32 !important;
}

.bmi-section-main .bmi-title {
    font-size: 21px !important;
}

.bmi-section-main .bmi-desc {
    font-family: 'metropillthin' !important;
}

.bmi-section-main .col-4.text-start.text-dark {
    font-family: 'metropill' !important;
    text-transform: uppercase;
}

.bmi-section-main .floating-label label {
    font-family: 'metropillthin' !important;
}

.bmi-section-main section.bmi-result p,
.bmi-section-main span,
.bmi-section-main a {
    font-family: 'metropill' !important;
}

.bmi-section-main button.ant-switch.bmi-switch.css-dev-only-do-not-override-ccdg5a.ant-switch-checked,
.bmi-section-main button.ant-switch.bmi-switch.css-dev-only-do-not-override-ccdg5a,
.bmi-section-main button.ant-switch {
    border-radius: 3px;
    box-shadow: none !important;
}

.bmi-section-main button.ant-switch .ant-switch-handle::before,
.bmi-section-main button.ant-switch.bmi-switch.css-dev-only-do-not-override-ccdg5a::before,
.bmi-section-main :where(.css-dev-only-do-not-override-ccdg5a).ant-switch .ant-switch-handle::before {
    border-radius: 3px;
}

.pricing-accordion {
    /* max-width: 1000px; */
    /* margin: 40px auto; */
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 25px 40px;
    position: relative;
    top: -60px;
    z-index: 99999;
}


.pricing-accordion .accordion-header {
    width: 100%;
    padding: 15px 20px;
    background: #202f32;
    border: none;
    color: #fff;
    font-family: 'metropillthin' !important;
    font-size: 17px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    line-height: 28px;
}

.pricing-accordion .accordion-arrow {
    transition: transform 0.3s ease;
}

.pricing-accordion .accordion-arrow.open {
    transform: rotate(180deg);
}

.pricing-content {
    padding: 20px;
    background: #fff;
}

.pricing-table {
    width: 100%;
}

.pricing-row {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}

.pricing-row.header {
    font-weight: 500;
    color: #666;
}

.service-col {
    flex: 1;
}

/* 
.price-col {
    width: 150px;
    text-align: right;
} */

.pricing-note {
    padding: 15px 0;
    color: #666;
    font-style: italic;
    border-bottom: 1px solid #eee;
}

.weightloss-wraper section.faq-section .accordion-item.active .accordion-header,
.weightloss-wraper section.faq-section .accordion-item .accordion-header:hover {
    background: transparent;
}

.weightloss-wraper .weight-loss-page .btn-primary,
.weightloss-wraper .btn-appointment.btn-primary,
.weightloss-wraper .form_wrap .btncust-pink {
    padding: 1.4166666667rem 2.2222222222rem;
}

section.consultation-bmi-section section.d-flex.justify-content-end {
    justify-content: center !important;
}

section.consultation-bmi-section section.d-flex.justify-content-end button.btn-pink.w-20.m-4 {
    padding: 1rem 3rem;
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    font-family: 'metropill' !important;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    width: auto !important;
    margin: 15px 0 !important;
    height: auto !important;
}

section.bmi-section-main-weightloss-page section.d-flex.justify-content-end button.btn-pink:hover:before {
    visibility: visible !important;
    color: #fff !important;
}

.weightloss-wraper section.bmi-section-main {
    position: relative;
}

.bmi-section-bg.img-banner-wl {
    width: 45%;
    height: 100%;
    background-size: cover;
    position: absolute;
    background-position: center;
    left: 0;
    top: 0;
}

@media (max-width: 1699px) {
    .weightloss-wraper .weight-loss-page {
        padding: 70px 0 50px;
    }

    .weightloss-wraper .weight-loss-page h1 {
        /* font-size: 66px;
        line-height: 66px; */
    }

    .weightloss-wraper .weight-loss-page p {
        /* font-size: 20px;
        line-height: 30px; */
    }

    .weightloss-wraper .weight-loss-page .btn-primary {
        margin-bottom: 50px;
    }

    .weightloss-wraper .weight-loss-page .btn-primary,
    .weightloss-wraper .btn-appointment.btn-primary,
    .weightloss-wraper .form_wrap .btncust-pink {
        /* padding: 1.3rem 2rem; */
        /* min-height: initial !important; */
    }

    .weightloss-wraper .weight-loss-page img {
        max-height: 400px;
    }

    .weightloss-wraper .intro-section h2 {
        /* font-size: 26px;
        line-height: 33px; */
    }

    .weightloss-wraper .intro-banner .banner-content {
        max-width: 52rem;
        padding: 3rem 4rem 5rem;
    }

    .weightloss-wraper .intro-banner .banner-content h2 {
        margin-bottom: 40px;
    }

    .weightloss-wraper .accordion-header {
        padding: 20px 15px;
    }

    .pricing-row.header div,
    .pricing-table .pricing-row,
    .pricing-table .pricing-note {
        font-size: 14px;
        line-height: 20px;
    }

    .weightloss-wraper .intro-banner .banner-content h2 {
        /* font-size: 25px;
        line-height: 31px; */
    }

    .weightloss-wraper .intro-section {
        padding: 90px 0 0;
    }

    .weightloss-wraper .intro-sec-inner {
        /* padding: 0 0 20px; */
    }

    .weightloss-wraper .weight-loss-products {
        padding: 0 0 70px;
    }

    .weightloss-wraper .product-content h3 {
        /* font-size: 30px;
        line-height: 34px; */
    }

    .weightloss-wraper .stat-number {
        font-size: 36px;
    }

    .weightloss-wraper .stat-text {
        line-height: 18px;
        font-size: 14px;
    }

    .weightloss-wraper .stat-item {
        gap: 15px;
    }

    .weightloss-wraper .stats-wrapper {
        gap: 20px;
    }

    .weightloss-wraper .how-it-works-section h2 {
        /* font-size: 36px; */
    }

    .weightloss-wraper .nav-tabs .nav-link {
        font-size: 20px;
    }

    .weight-display,
    .loss-amount span {
        font-size: 32px !important;
    }

    .weightloss-wraper .tab-pane p {
        font-size: 16px;
    }

    .weightloss-wraper .how-it-works-section {
        /* padding: 70px 0; */
    }

    .weightloss-wraper section.bmi-section-main {
        padding: 70px 0;
    }

    .weightloss-wraper .bmi-section-main form.w-form {
        margin: 0;
    }

    .weightloss-wraper .unique-objects-content h2 {
        /* font-size: 56px;
        line-height: 56px; */
    }

    .weightloss-wraper .unique-objects-content .stat-number {
        /* font-size: 26px;
        line-height: 29px; */
    }

    .weightloss-wraper .unique-objects-content .stat-title {
        /* font-size: 17px;
        line-height: 21px; */
    }

    .weightloss-wraper .unique-objects-content .stat-description {
        /* font-size: 14px;
        line-height: 22px; */
    }

    .weightloss-wraper .faq-title,
    .weightloss-wraper .testimonials-header h2 {
        /* font-size: 26px;
        line-height: 33px; */
    }

    .weightloss-wraper .form_wrap h3 {
        /* font-size: 26px;
        line-height: 33px; */
    }

    .weightloss-wraper .custom-inputdark input,
    .weightloss-wraper .custom-inputdark textarea {
        /* padding: 14px 16px !important; */
        padding: 1.1944444444rem 2rem;
        font-size: 15px !important;
    }

    .weightloss-wraper .custom-inputdark textarea {
        height: 150px;
    }

    .weightloss-wraper .quote-mark {
        top: -9px;
    }

    .weightloss-wraper .quote-mark img {
        max-width: 26px;
    }

    .weightloss-wraper .testimonial-card {
        /* margin: 0; */
    }

    .weightloss-wraper .testimonial-text {
        text-align: left;
        margin-bottom: 0;
    }
}

@media (max-width: 1199px) {
    .weightloss-wraper .btn-appointment.btn-primary {
        min-height: initial !important;
    }

    .weightloss-wraper .unique-objects-content {
        padding: 80px 0;
    }

    .weightloss-wraper .weight-loss-page {
        padding: 40px 0 20px;
    }

    .weightloss-wraper .banner-content {
        padding: 20px;
    }

    .weightloss-wraper .weight-loss-page h1 {
        /* font-size: 56px;
        line-height: 56px; */
    }

    .weightloss-wraper .weight-loss-page p {
        /* font-size: 18px;
        line-height: 28px; */
    }
}

@media (max-width: 767px) {
    .weightloss-wraper .unique-objects-content h2 {
        font-size: 3.5rem;
    }

    .weightloss-wraper .breadcrumnb-img-bg-weightloss {
        padding-top: 20px;
    }

    .weightloss-wraper .weight-loss-page h1 {
        font-size: 2.8rem;
        margin-bottom: 40px;
        /* font-size: 36px;
        line-height: 36px; */
    }

    .weightloss-wraper .weight-loss-page p {
        margin-bottom: 40px !important;
    }

    .weightloss-wraper .weight-loss-page {
        padding: 0;
    }

    .weightloss-wraper .banner-content {
        padding: 20px 0;
    }

    .weightloss-wraper .weight-loss-page .btn-primary {
        margin-bottom: 40px;
    }

    .img-banner-wrap {
        display: none;
    }

    .weightloss-wraper .weight-loss-page p {
        /* font-size: 16px;
        line-height: 25px; */
    }

    /* .form_wrap p {
        font-size: 16px;
        line-height: 25px;
    } */
    .weightloss-wraper .intro-section {
        padding: 50px 0 0;
    }

    .weightloss-wraper .intro-section h2,
    .weightloss-wraper .how-it-works-section h2 {
        /* font-size: 25px;
        line-height: 30px; */
    }

    .weightloss-wraper .intro-banner .banner-content {
        max-width: 100%;
        padding: 3rem 1rem 5rem;
    }

    .weightloss-wraper .intro-banner .banner-content h2 {
        /* font-size: 22px;
        line-height: 29px; */
    }

    .weightloss-wraper .intro-banner .banner-content h2 span {
        display: inline;
    }

    .weightloss-wraper .weight-loss-page .btn-primary,
    .weightloss-wraper .btn-appointment.btn-primary,
    .weightloss-wraper .form_wrap .btncust-pink {
        /* padding: 16px 15px; */
        font-size: 11px;
        line-height: normal;
        /* min-height: initial; */
    }

    .weightloss-wraper .intro-banner {
        background-position: right;
    }

    .weightloss-wraper .accordion-header {
        padding: 15px 15px;
    }

    .weightloss-wraper .product-card {
        padding: 30px 15px;
        margin-bottom: 25px;
        height: auto;
    }

    .flex-nowraps {
        /* flex-wrap: nowrap;
        overflow-x: auto; */
    }

    .weightloss-wraper .weight-loss-products {
        padding: 0 0 40px;
    }

    .weightloss-wraper .product-content h3 {
        /* font-size: 24px;
        line-height: 27px; */
    }

    .weightloss-wraper .testimonials-header h2 {
        /* font-size: 24px;
        line-height: 27px; */
    }

    .weightloss-wraper .faq-title {
        /* font-size: 24px;
        line-height: 27px; */
    }

    .weightloss-wraper .form_wrap h3 {
        /* font-size: 24px;
        line-height: 27px; */
    }

    .weightloss-wraper .stat-number {
        font-size: 30px;
    }

    .weightloss-wraper .stat-text {
        font-size: 12px;
    }

    .weightloss-wraper .container {
        padding: 0px 0 !important;
    }

    .weightloss-wraper .how-it-works-section h2 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .weightloss-wraper .how-it-works-section {
        /* padding: 40px 0; */
    }

    .weightloss-wraper .nav-tabs {
        flex-direction: row;
        gap: 15px;
    }

    .weightloss-wraper .nav-tabs .nav-link {
        font-size: 16px;
        padding: 10px 15px;
    }

    .weightloss-wraper section.bmi-section-main {
        padding: 40px 0 70px;
    }

    .weightloss-wraper .graph-wrap,
    .img-wrap-graph {
        margin-top: 40px;
    }

    .weightloss-wraper .bmi-section-main .bmi-title {
        font-size: 18px !important;
    }

    .weightloss-wraper .bmi-section-main .bmi-desc {
        font-size: 16px !important;
    }

    .weightloss-wraper .bmi-input .floating-label input {
        min-height: 40px !important;
    }

    .weightloss-wraper .w-20 {
        width: 50% !important;
    }

    .weightloss-wraper .unique-objects-content h2 {
        /* font-size: 40px;
        line-height: 40px; */
    }

    .weightloss-wraper .unique-objects-content .stat-number {
        /* font-size: 25px;
        line-height: 27px; */
    }

    .weightloss-wraper .unique-objects-content .stat-title,
    .weightloss-wraper .unique-objects-content .stat-description {
        /* font-size: 13px;
        line-height: 20px; */
    }

    .weightloss-wraper .unique-objects-content {
        position: relative;
        z-index: 99999;
        padding: 0 0;
    }

    .weightloss-wraper .unique-objects-image {
        display: none;
    }

    .form_wrap {
        max-width: 300px;
        margin: 0 auto;
        padding: 70px 0;
    }

    html body .weightloss-wraper .faq-title {
        margin-bottom: 30px;
    }

    .weightloss-wraper .custom-inputdark input,
    .weightloss-wraper .custom-inputdark textarea {
        /* padding: 12px 14px !important; */
        font-size: 14px !important;
    }

    .weightloss-wraper .weight-loss-page .btn-primary,
    .weightloss-wraper .btn-appointment.btn-primary,
    .weightloss-wraper .form_wrap .btncust-pink {
        /* padding: 16px 15px !important; */
        font-size: 11px !important;
        line-height: normal !important;
        /* min-height: initial !important; */
    }

    .weightloss-wraper .accordion-title {
        font-size: 16px;
        line-height: 19px;
    }

    .accordion-content {
        /* padding: 10px 30px; */
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .weightloss-wraper .accordion-content p {
        font-size: 14px;
        line-height: 21px;
    }

    .testi-wrap-content-inner {
        padding: 40px 0 40px;
    }

    .weightloss-wraper .testimonials-header {
        margin-bottom: 30px;
    }

    .weightloss-wraper .testimonial-card {
        margin: 15px 0px;
    }

    .footer-new {
        background-color: #F2F2F2;
        padding: 40px 0 10px 0;
    }

    .footer-new {
        text-align: center;
    }

    .d-flex.gap-3.align-items-start.mb-3 {
        justify-content: center;
    }

    .mar-list .list li {
        margin-bottom: 10px;
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .list>ul {
        list-style: none;
        padding-left: 0px;
        margin: 0;
        column-count: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .col-xl-6.col-lg-4+.col-xl-3.col-lg-4 {
        text-align: center;
    }

    .logo-footer {
        width: 250px;
        margin: 20px 0;
    }

    .d-flex.align-items-center.gap-3.justify-content-end.py-4 {
        justify-content: center !important;
    }

    .d-flex.gap-10p.align-items-start.mb-3 {
        justify-content: center;
    }

    .copy-right-para {
        padding: 10px 0;
        font-size: 14px;
        line-height: 24px;
        font-family: 'metropillthinner' !important;
    }

    .weight-display,
    .loss-amount span {
        font-size: 28px !important;
    }

    .weightloss-wraper .fs-21 {
        font-size: 16px !important;
    }



}

@media (max-width: 1439px) {
    html {
        font-size: 16px;
    }
}

@media (max-width: 1279px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .weightloss-wraper .bmi-section-main h2 {
        margin-top: 30px;
    }

    .weightloss-wraper .form_wrap form.ant-form.ant-form-vertical {
        padding: 0 !important;
    }

    .logo {
        display: flex;
        align-items: center;
    }

    html {
        font-size: 13px;
    }

    .weightloss-wraper .how-it-works-section {
        padding: 40px 0;
    }

    .weightloss-wraper .stats-wrapper {
        gap: 40px;
    }

    .weightloss-wraper .nav-tabs {
        margin-top: 50px;
    }
}

/* Updated Graph Styles */
.weightloss-wraper .graph-wrap {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.weightloss-wraper .graph-title {
    font-size: 24px;
    color: #202f32;
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
}

.weightloss-wraper .graph-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
}

.weightloss-wraper .weight-target {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.weightloss-wraper .weight-stat {
    text-align: center;
}

.weightloss-wraper .weight-label {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.weightloss-wraper .weight-value {
    font-size: 24px;
    color: #202f32;
    font-weight: 600;
}

.weightloss-wraper .weight-value.target {
    color: #357550;
}

.weightloss-wraper .graph-container {
    position: relative;
    height: 400px;
    margin-top: 20px;
}

.weightloss-wraper .graph-legend {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}

.weightloss-wraper .legend-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.weightloss-wraper .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.weightloss-wraper .legend-color.current {
    background-color: #202f32;
}

.weightloss-wraper .legend-color.target {
    background-color: #357550;
}

.weightloss-wraper .legend-text {
    font-size: 14px;
    color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
    .weightloss-wraper .graph-wrap {
        padding: 20px;
    }

    .weightloss-wraper .graph-container {
        height: 300px;
    }

    .weightloss-wraper .weight-value {
        font-size: 20px;
    }
}

section.bmi-section-main-weightloss-page section.d-flex.justify-content-end button.btn-pink {
    position: relative;
    min-width: 300px;
    background: #e2b9b3 !important;
    border: none;
}

section.d-flex.justify-content-end button.btn-pink {
    background: #e2b9b3 !important;
    border: none !important;
}

section.bmi-section-main section.d-flex.justify-content-end button.btn-pink:after {
    background-color: #202f32 !important;
}

.bmi-section-main section.d-flex.justify-content-end button.btn-pink span {
    transition: 0.3s;
}

.bmi-section-main section.d-flex.justify-content-end button.btn-pink:hover span {
    color: #fff !important;
}

section.bmi-section-main-weightloss-page section.d-flex.justify-content-end button.btn-pink:before {
    content: 'Start Consultation ';
    visibility: visible;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;
    text-align: center;
}

section.bmi-section-main-weightloss-page section.d-flex.justify-content-end button.btn-pink span {
    visibility: hidden;
}