

.shadow-c{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


/* card-2 btn */

.btn-show{
    padding: 10px 30px;
    background-color: #202f32;
    color: #fff;
    font-size: 14px;
    border: 0;
    white-space: nowrap;
    transition: .3s all;
    margin-top: 30px;
    position: relative;
    background-position: left bottom;
    z-index: 1;
}



.border-main{
    border-top: 3px solid #e2b9b3 !important;
}
.border-pro{
    border-top: 3px solid #357550 !important;
}


.btn-show:hover{
    background-position: 100%;
    color: #fff;
}

.btn-show:hover:after{
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-position: 0 0;
}

.btn-show::after{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.5px;
    right: -0.5px;
    bottom: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: -webkit-transform 400ms ease-in-out;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    background: #e2b9b3;
    background-position: 0 0;
}
.btn-show.active{
    background-color: #e2b9b3;
    color: #fff;
}



.btn-green{
    padding: 10px 30px;
    background-color: #202f32;
    color: #fff;
    font-size: 14px;
    border: 0;
    white-space: nowrap;
    transition: .3s all;
    margin-top: 30px;
    position: relative;
    background-position: left bottom;
    z-index: 1;
}
.btn-green:hover{
    background-position: 100%;
    color: #fff;
}

.btn-green:hover:after{
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-position: 0 0;
}

.btn-green::after{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.5px;
    right: -0.5px;
    bottom: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: -webkit-transform 400ms ease-in-out;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    background: #357550;
    background-position: 0 0;
}
.btn-green.active{
    background-color: #357550;
    color: #fff;
}





.tag-btn3{
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 400;
    border: 1.3px solid #e2b9b3 !important; 
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    border-radius: 12px 0px 12px 0px
}


.btncust-tb{
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 13px;
    color: #000;
    background-color: #F1F2F6;
    color: #000;
    transition: .3s all;
    width: auto;
    border: 0px;
}

.activebg{
    background-color: #e2b9b3;
    color: #fff;
}
.activebgpro{
    background-color: #357550;
    color: #fff;
}

.search-input{
    border: 1px solid #000;
}


.search-input input{
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 400;
    color: #2D2E2F;
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
}

.search-input .input-group-addon label{
    height: 50px;
    text-align: center;
    width: 50px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    background-color: transparent;
}

.tag-btn {
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 400;
    border: 1.3px solid #e2b9b3;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tag-btn-green {
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 400;
    border: 1.3px solid #357550;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 991px){
    .mt-mbl{
        margin-top: 50px !important;
    }
}
.form-control{
    box-shadow: none !important;
}
.custom-collapse{
    border: none;
    background-color: transparent;
    max-height: 600px; /* Adjust this value as needed */
    overflow-y: auto;
} 

.custom-collapse::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.custom-collapse::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.custom-collapse::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

/* .custom-panel {
    max-height: 300px;
    overflow-y: auto;
} */
.btn-close-icon{
    padding: 10px 30px;
    background-color: #202f32;
    color: #fff;
    font-size: 14px;
    border: 0;
    white-space: nowrap;
    transition: .3s all;
    position: relative;
    background-position: left bottom;
    z-index: 1;
}
.btn-close-icon:hover{
    background-position: 100%;
    color: #fff;
}

.btn-close-icon:hover:after{
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-position: 0 0;
}
.btn-close-icon::after{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.5px;
    right: -0.5px;
    bottom: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: -webkit-transform 400ms ease-in-out;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    background: #e2b9b3;
    background-position: 0 0;
}
