.loader-container {
    display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        backdrop-filter: blur(20px);
        left: 0;
        top: 0;
        background: #fff;
        opacity: 0.8;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #e4b7b7;
    border-radius: 50%;
    border-top: 5px solid #202f32;  /* Light pink color matching the theme */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} 

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 