.bg-filter{
    background-color: #EFEFEF;
    position: sticky;
    top: 100px;
    right: 0px;
    width: 100%;
    min-height: 100vh;
}



.gap-6s{
    gap: 6px;
}

.btncust{
    padding: 1rem 3.1111111111rem;
    text-align: center;
    display: block;
    width: 100%;
    background-color: #202f32;
    color: #fff;
    transition: .3s all;
    border: 0px;
    position: relative;
    background-position: left bottom;
    z-index: 1;
}


.btncust:hover{
    background-position: 100%;
    color: #fff;
}
.btncust:hover .colorload svg{
    color: #202f32 !important;
}

.btncust:hover:after{
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    background-position: 0 0;
}

.btncust::after{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: -0.5px;
    right: -0.5px;
    bottom: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transition: -webkit-transform 400ms ease-in-out;
    transition: -webkit-transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out;
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
    background: #e2b9b3;
    background-position: 0 0;
}

@media (max-width: 991px){
    .bg-filter{
        display: none;
    }
}
.side-collapse {
    border: none;
    background-color: transparent;
    max-height: 400px; /* Adjust this value as needed */
    overflow-y: auto;

    /* For WebKit-based browsers (Chrome, Safari) */
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #e0e0e0 transparent; /* Firefox */
}

.side-collapse::-webkit-scrollbar {
    width: 6px; /* Width of the vertical scrollbar */
}

.side-collapse::-webkit-scrollbar-thumb {
    background-color: #e0e0e0; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
}

.side-collapse::-webkit-scrollbar-track {
    background: transparent; /* Color of the scrollbar track */
}

/* Optional: Hover effect for the scrollbar thumb */
.side-collapse::-webkit-scrollbar-thumb:hover {
    background-color: #bdbdbd; /* Darker color when hovered */
}
