/* CheckoutStyle.css */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


.navLink {
    color: black !important;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.03cm;
}
.navLink:hover{
    color: #D17C4C !important;
}
.theme-font{
    font-family: 'Playfair Display', serif !important;

}





  





  
  
