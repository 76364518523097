.date-picker {
    /* border: 0.124rem solid black; */
    padding: 10px;
  }
  
  .calendar {
    display: flex;
    flex-direction: column;
  }
  
  .month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    color: black;
    text-transform: uppercase;
    font-family: metropill !important;
    margin-bottom: 20px;
  }
  
  .arrow {
    cursor: pointer;
  }
  
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .weekdays, .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    color: black;
  }
  .weekdays, .days, .day {
    font-family: metropill !important;
  }
  
  .day {
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    color: #1d1d1d;
  }
  

  .day.selected {
    background-color: black;
    color: white;
    border-radius: 50%;
    width: 30px; 
    height: 30px; 
    display: flex; 
    justify-content: center;
    align-items: center;
    margin: auto; 
    transition: background-color 0.3s ease, color 0.3s ease; 
}
  
  .day.disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  .prev-month-day.disabled{
    color: #ccc;
    cursor: not-allowed;
    font-family: metropill !important;
    padding: 10px;
    font-size: 12px;

  }
  .monthName{
    font-family: metropill !important;
  }
  