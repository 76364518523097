.product-selection-page {
  min-height: 100vh;
  padding-bottom: 80px; /* Space for fixed checkout bar */
  background-color: #fff;
}

.product-header {
  /* padding: 20px; */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.center-logo {
  width: 175px;
  object-fit: contain;
}

/* prducts boxes */
.weight-loss-products h1 {
    font-weight: 300;
    margin: 30px 0 50px;
    line-height: 1.2352em;
    font-size: 1.88889rem;
}
.weight-loss-products {
    padding: 0px 0 120px;
    background-color: #f8f9fa;
    background-color: #fff;
}

.product-card {
    background: #fff;
    border-radius: 0;
    padding: 20px;
    height: 100%;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    box-shadow: none;
    transition: transform 0.3s ease;
    display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #464646;
}

.product-card:hover {
    transform: translateY(-5px);
}

.product-card .btncust-pink{
    background-color: #202f32 !important;
    color: #fff !important;
    text-decoration: none !important;
}
.discount-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #E2B9B3;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'metropillthin' !important;
}

.product-image {
    text-align: center;
    margin-bottom: 30px;
}

.product-image img {
    /* max-width: 200px; */
    max-width: 100%;
    width: 100%;
    height: auto;
}

.product-content h3 {
    color: #202f32;
    /* font-size: 28px;
    margin-bottom: 20px; */
    font-size: 34px;
    line-height: 42px;
    font-weight: 300;
    margin-bottom: 30px;
    font-size: 1.9rem;
    line-height: 2.1rem;
}

.product-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.tag {
    background: rgba(226, 185, 179, 0.1);
    color: #202f32;
    padding: 6px 12px;
    border-radius: 15px;
    font-size: 14px;
    font-family: 'metropillthin' !important;
}

.tag.status {
    background: #202f32;
    color: #fff;
    font-family: 'metropillthin' !important;
}

.product-features {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
}

.product-features li
 {
    padding-left: 30px;
    position: relative;
    margin-bottom: 15px;
    color: #202f32;
    font-family: 'metropillthin' !important;
}

p{
    position: relative;
    margin-bottom: 15px;
    color: #202f32;
    font-family: 'metropillthin' !important;
}
.product-features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #E2B9B3;
    font-weight: bold;
}

.product-pricing {
    margin-bottom: 30px;
    font-family: 'metropillthin' !important;
}

.price-block {
    margin-bottom: 15px;
}

.price-block .label {
    display: block;
    color: #202f32;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: 'metropillthin' !important;
    position: unset;
    transform: translate(0,0);
}

.price {
    font-size: 24px;
    font-weight: 600;
    color: #202f32;
    margin-right: 10px;
    font-family: 'metropillthin' !important;
}

.original-price {
    text-decoration: line-through;
    color: #999;
    font-family: 'metropillthin' !important;
}

.promo-code {
    font-size: 14px;
    color: #202f32;
    margin-bottom: 10px;
    font-family: 'metropillthin' !important;
}

.promo-code strong {
    color: #E2B9B3;
    font-family: 'metropillthin' !important;
}

.price-note {
    font-size: 12px;
    color: #666;
    font-family: 'metropillthin' !important;
}

.btn-primary {
    background: #202f32;
    border: none;
    padding: 12px 30px;
    border-radius: 25px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background: #E2B9B3;
    color: #202f32;
}
.img-wrap-graph img {
    width: 100%;
    max-height: 475px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .product-card {
        margin-bottom: 30px;
    }
    
    .product-image img {
        /* max-width: 150px; */
    }
}
/* product boxes */

.tag{
    border: none;
    transition: 0.3s;
}
.tag.active,
.tag.active:hover{
    background: #202f32;
    color: #fff;
}
.tag:hover{
    background: #E2B9B3;
    color: #fff;
}

.variation-btn {
  background: rgba(226, 185, 179, 0.1);


  color: #202f32;
  padding: 6px 12px;
  border-radius: 15px;
  border: none;
  font-size: 14px;
  font-family: 'metropillthin', sans-serif !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.variation-btn.active {
  background: #202f32;
  color: #fff;
}

.variation-btn:hover {
  background: #E2B9B3;
  color: #202f32;
}

.checkout-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f0ee !important;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  font-family: 'metropill', sans-serif !important;
}

.selection-info {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: 'metropill', sans-serif !important;
  flex-wrap: wrap;
}

.selection-info img {
  height: auto;
  width: 75px;
  object-fit: contain;
}
.selection-info span{
    font-family: 'metropill', sans-serif !important;
}
.checkout-btn {
  font-family: 'metropill', sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 1.1rem 2rem;
  background: linear-gradient(to right, #202f32 50%, #e2b9b3 50%) no-repeat scroll right bottom / 210% 100% #e2b9b3;
  color: #202f32;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.6s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.checkout-btn:hover {
  color: #fff;
  background-position: left bottom;
}

.checkout-btn.disabled {
  background: #ccc;
  cursor: not-allowed;
}



.add-to-cart-btn {
    font-family: 'metropill', sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    padding: 0.8rem 1.4rem;
    background: linear-gradient(to right, #202f32 50%, #e2b9b3 50%) no-repeat scroll right bottom / 210% 100% #e2b9b3;
    color: #202f32;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.6s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .add-to-cart-btn:hover {
    color: #fff;
    background-position: left bottom;
  }


@media (max-width: 991px) {
  .products-grid {
    grid-template-columns: 1fr;
    padding: 20px;
  }
  
  .checkout-bar {
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  
  .selection-info {
    width: 100%;
    justify-content: center;
  }
  
  .checkout-btn {
    width: 100%;
  }
} 