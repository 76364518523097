
.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.transparent-bg{
    background-color: transparent;
}


.chemist-navbar{display: flex;justify-content:center; 
    transition-property:all;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    transition-timing-function:linear;transition-duration:0.5s;width:100%;position: fixed;z-index: 100;top:45px
    }
.chemist-navbar-move{
    top:-400px
}

.chemist-navbar-top0{
    top: 0;
}

.chemist-navbar-middle{
    margin-bottom: 0px;
    padding-left: 0px;
}
.chemist-navbar>.chemist-navbar-inner{width:86%;flex-wrap: wrap;display: flex;align-items: center;}
.chemist-navbar>.chemist-navbar-inner>.chemist-navbar-left{width:17%;padding:11px 0;transition-timing-function:linear;transition-duration:0.5s;}

.chemist-navbar .chemist-navbar-left .two{
    display: none;
}
.chemist-navbar>.chemist-navbar-inner>ul{display: flex;list-style-type: none;}



.items .nav-arrow{transform: translateX(-12px);visibility: hidden; color: var(--chemist) !important;}
.items .item-name{color: #202f32;font-size: 11.5px;font-weight: 500;letter-spacing: 0.15rem;transition: .5s; }
.items .item-name:hover{
    color: #357550 !important;
}
.items .nav-arrowDown{transform: rotate(0deg);}
.items .nav-item{
    padding: 0.5555555556rem 1.1111111111rem;
    text-decoration: none;
}
.chemist-navbar .items{
    position: relative;
    /* padding:0 0 0 30px; */
    display: flex;
    align-items: center;
}


/* chemistnavbar small */
.chemist-navbar-small{
    display:none;
    justify-content:center;
   width:100%;
   overflow: hidden;
   position: fixed;
   top:0px;
   z-index: 100;
   border-bottom: 1px solid rgba(255,255,255, 0.1);
}
.chemist-navbar-small-move{
    top:-400px
}
.chemist-navbar-small>.chemist-navbar-inner{
    width:90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.chemist-navbar-small>.chemist-navbar-inner>.chemist-navbar-left{
    /* width:120px; */
    width:140px;
    padding:11px 0;
    display: flex;
    align-items: center;
    transition-timing-function:linear;
    transition-duration:0.5s;
}
.chemist-navbar-small>.chemist-navbar-inner>.chemist-navbar-right{
    display: flex;
    justify-content:flex-end;
    align-items:center;
    gap: 20px;
}
.chemist-navbar-small .sp-cover{
    cursor: pointer;
}

.chemist-navbar-small .sp-cover .sp{
    height: 2px;
    width: 22px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.chemist-navbar-small .sp-cover .sp,
.chemist-navbar-small .sp-cover .sp::before,
.chemist-navbar-small .sp-cover .sp::after{
    background-color: #202f32;
}

.chemist-navbar-small .sp-cover .sp::before{
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    display: inline-block;
    height: 2px;
    width: 22px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

}

.chemist-navbar-small .sp-cover .sp::after{
    content: '';
    position: absolute;
    bottom: 8px;
    left: 0;
    display: inline-block;
    height: 2px;
    width: 22px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.chemist-navbar-small .sp-cover:hover .sp::before{
    webkit-transform: matrix(0.5, -0.3, 0, 1, 5, -4);
    -ms-transform: matrix(0.5, -0.3, 0, 1, 5, -4);
    transform: matrix(0.5, -0.3, 0, 1, 5, -4);
    height: 1px;
}

.chemist-navbar-small .sp-cover:hover .sp::after{
    -webkit-transform: matrix(0.5, 0.3, 0, 1, 5, 4);
    -ms-transform: matrix(0.5, 0.3, 0, 1, 5, 4);
    transform: matrix(0.5, 0.3, 0, 1, 5, 4);
    height: 1px;
}

.chemist-navbar-small .sp-cover:hover .sp{
    height: 1.5px;
}

.cart-nav .carttwo{
    display: none;
    transform: scale(1);
}


.cart-nav:hover .carttwo{
    display: block !important;
    transform: scale(0.9);
}

.cart-nav:hover .cartone{
    display: none;    
    animation: hideshow 1s infinite alternate;
}

.secondary-bg{
    background-color: #fff !important;
}

@keyframes hideshow{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.fs-12{
    font-size: 12px ;
}

.drop-none{
    display: none !important;
}


.bg-banner{
    background-image: url('../../../public/assets/images/banner/banner-sample.jpg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 92px;
    padding-bottom: 92px;
    width: 100%;
    min-height: 70vh;
}
.breadcrumb-link{
    font-size: 0.8888888889rem;
    color: #949494;
}
.cart_counter{
    position: absolute;
    margin-left: 15px;
    margin-top: -15px;
    background: pink;
    padding: 5px;
    width: 20px;
    height: 20px;
    justify-content: center;
    text-align: center;
    line-height: 0;
    align-items: center;
    border-radius: 50px;
    padding-top: 8px;
    color: white;
}
.cart_counter_mob{
    position: absolute;
    /* margin-left: 7px; */
    margin-top: -25px;
    background: pink;
    padding: 5px;
    width: 20px;
    height: 20px;
    justify-content: center;
    text-align: center;
    line-height: 0;
    align-items: center;
    border-radius: 50px;
    padding-top: 8px;
    color: white;
}

@media screen and (max-width:767px) 
{
    .chemist-navbar-small{display: flex;}
    .chemist-navbar{display: none;}
    
}

@media (min-width: 768px) and (max-width: 1023px){
    .first-hide{
        display: none !important;
    }
}

@media (min-width:1024px) and (max-width: 1280px){
    .chemist-navbar>.chemist-navbar-inner {
        width: 97%;
    }
    .items .nav-item {
        padding: 0.5555555556rem 1rem;
    }

}

@media (max-width: 1280px){
    .drop-none{
        display: flex !important;
    }
    .second-hide{
        display: none !important;
    }
}