.breadcrumnb-img-bg{
    background-image: url('../../../public/assets/images/banner/banner-sample.jpg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 55px;
    padding-bottom: 80px;
    width: 100%;
    height: 100%;
}


.breadcrumb-link{
    /* font-size: 14px !important;
    color: #949494;
    letter-spacing: 0.10rem;
    display: flex; */
    font-size: 1rem;
    color: #949494;
    display: flex;
    font-weight: 300;
    font-style: normal;
    line-height: 19px;
    text-transform: none;
    letter-spacing: 0px;
}

.w-font{
    width:70%
}

.dot-bread{
    position: relative;
}

.dot-bread::before{
    content: '•';
    position: absolute;
    top: 0px;
    left: -20px;
}

.hover-link{
    text-decoration: none !important;
}

.hover-link span:hover{
    color: #e2b9b3 !important;
}

.fs-5rem{
    font-size: 5rem !important;
}

/* @media (max-width: 1200px){
    .breadcrumnb-img-bg {
        padding-top: 30px;
    }
} */

@media (max-width: 991px){
    .breadcrumnb-img-bg{
        background: #f2f1ef !important;
        /* min-height: 60vh !important; */
        padding-bottom: 0px !important;
        padding-top: 20px !important;
    }
    .breadcrumb-link{
        font-size: 12px !important;
    }
    .w-font{
        width:100% !important; 
    }
}

/* @media (min-width: 768px) and (max-width: 991px){
    .breadcrumnb-img-bg{
        min-height: 60vh !important;
    }
} */

@media (max-width: 480px){
    .fs-5rem{
        font-size: 2rem !important;
    }
}

@media (min-width: 481px) and (max-width: 1199px){
    .fs-5rem{
        font-size: 3rem !important;
    }
}

@media (min-width: 1700px){
    .fs-5rem{
        font-size: 4.44444rem !important;
    }
}
