.w-form{
    width: 550px;
    width: 650px; 
    margin: 40px auto;
    max-width: 100%;
    transition: opacity .5s ease, transform .5s ease;
}

body{
  transition: .5s all;
}

.mt-30{
    margin-top: 30px;
}

.padc{
    padding: 13px 15px;
}

.form-check-input{
    border-color: #e2b9b3;
}

.min-height{
    min-height: 100vh;
}

.form-check-input {
    width: 1.2rem !important;
    height: 1.2rem !important;
    border-width: 1.5px !important;
    margin-bottom: 7px;
    margin-right: 10px;
}

.form-check-input:focus{
    box-shadow: none;
}

.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.fade-out {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-inn {
  opacity: 1;
  transform: translateX(0);
}

.fade-outt {
  opacity: 0;
  transform: translateX(-100px);
}

.animatecs{
  transition: opacity .5s ease, transform .5s ease;
  height: 0;
}

.animatecs.active{
  height: auto;
}

.active-check{
  background-color: #e2b9b338;
}

.bullet{
  cursor: pointer;
  border: 1px solid #b8adad;
}

.label-cs{
  border: 1px solid #333;
  border-radius: 50%;
  height: 20px;
  line-height: 31px;
  width: 20px;
  transition: background-color .3s ease;
}
.label-check{
  border-radius: 4px;
  border: 1px solid #333;
  height: 20px;
  line-height: 31px;
  width: 20px;
  transition: background-color .3s ease;
}

.label-cs + span{
  max-width: calc(100% - 50px);
}

.bullet.active-check .label-cs{
  background: url(../../../public/assets/images/checked.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  color: #0000;
  background-color: #333;
}

.bullet.active-check .label-check{
  background: url(../../../public/assets/images/checked.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  color: #0000;
  background-color: #333;
}

.bullet:hover .label-cs {
  background-color: #333 !important;
}

.bullet:hover .label-check {
  background-color: #333 !important;
}
.btn-disable{
    background-color: #c7c2c2;
    padding: 1rem 3.1rem;
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
    transition: .3s all;
    border: 0px;
    position: relative;
    background-position: left bottom;
    z-index: 1;
}
.quiz-text{
  background: transparent;
  border: 1px solid #b8adad;
}

.conslut-loading {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  display: inline-block;
  width: 48px;
  height: 48px;
  color: #b72727;
}
  .conslut-loading:before,
  .conslut-loading:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .conslut-loading:after {
    color: #FF3D00;
    transform: rotateY(70deg);
    animation-delay: .4s;
  }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}


    


/* new style */

/* Fade animations that Bmi.jsx expects */
.w-form.w-form-bmi .form-control:focus{
  background-color: transparent !important;
}
.w-form-bmi .btn-primary {
  background: #202f32;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.w-form-bmi .btn-primary:hover {
  background: #E2B9B3;
  color: #202f32;
}



.w-form-bmi section.bmi-section-main {
  background: #f1f0ee;
  padding: 90px 0;
}

.w-form-bmi .bmi-section-main .bmi-result{
  border-radius: 0;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #fff !important;
}
form.w-form.w-form-bmi{
  /* border: 1px solid #202f32; */
  background-color: #E2B9B3 !important;
  background-color: #f9f0ee !important;
  border: 1px solid #e2abab !important;
}
form.w-form.w-form-bmi .bmi-section-main .bmi-header{
  padding-top: 25px;
}
form.w-form.w-form-bmi .bmi-section-main .bmi-header,
form.w-form.w-form-bmi .bmi-section-main .bmi-inputs-height,
form.w-form.w-form-bmi .bmi-section-main .bmi-inputs-weight{
  padding-left: 25px;
  padding-right: 25px;
}

.w-form-bmi .bmi-section-main .bmi-switch.ant-switch{
  background: #202f32 !important;
}

.w-form-bmi .bmi-section-main .bmi-switch .ant-switch-inner-checked,
.w-form-bmi .bmi-section-main .bmi-switch .ant-switch-inner-unchecked{
  font-family: 'metropillthin' !important;

  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-form-bmi .bmi-section-main .segment-bar .indicator {
  opacity: 1;
}
.w-form-bmi .bmi-section-main .segment-bar * {
  /* opacity: 0.3; */
}
.w-form-bmi .bmi-section-main .segment-bar .segment.underweight{
  background-color: #f1f0ee;
}
.w-form-bmi .bmi-section-main .segment-bar .segment.healthy{
  background-color: #f1f0ee;
  /* border-right: 5px solid #fff; */
}
.w-form-bmi .bmi-section-main .segment-bar .segment.overweight{
  background-color: #357550 !important;
  /* border-right: 5px solid #fff; */
  background: #e2b9b3 !important;
  opacity: 0.5;
}
.w-form-bmi .bmi-section-main .segment-bar .segment.obese{
  background-color: #E2B9B3 !important;
}
.w-form-bmi .bmi-section-main section.d-flex.justify-content-end,
.w-form-bmi.bmi-section-main .bmi-result {
  background: #fff !important;
}
.w-form-bmi.bmi-section-main section.bmi-result p, .bmi-section-main span, .bmi-section-main a{
  color: #202f32 !important;
}
form.w-form.w-form-bmi .bmi-section-main {
  margin: 0;
  /* background: #357550; */
  background: transparent !important;
  padding: 0 !important;
}
.w-form-bmi span.ant-switch-inner span {
  color: #fff !important;
}
.w-form-bmi .bmi-section-main section.bmi-result .success-section>p:nth-child(1) {
  color: #357550 !important;
}
.w-form-bmi .bmi-section-main section.bmi-result .error-section>p:nth-child(1) {
  color: rgba(255, 0, 0, 0.59) !important;
}
.w-form-bmi section.bmi-section-main .consultation-bmi-section .text-danger {
  color: rgb(137 45 54) !important;
}
.w-form-bmi .bmi-section-main .bmi-title,
.w-form-bmi .bmi-section-main .bmi-desc,
.w-form-bmi .bmi-section-main .col-4.text-start.text-dark,
.w-form-bmi .bmi-section-main .bmi-input .floating-label input,
.w-form-bmi .bmi-section-main .bmi-input .floating-label input::placeholder{
  color: #fff !important;
  color: #202f32 !important;
}
.w-form-bmi .bmi-section-main .bmi-input .floating-label input{
  border-color: #fff !important;
  border-color: #202f32 !important;
}
.w-form-bmi .bmi-section-main .bmi-title{
  font-size: 21px !important;
}
.w-form-bmi .bmi-section-main .bmi-desc{
  font-family: 'metropillthin' !important;
}
.w-form-bmi .bmi-section-main .col-4.text-start.text-dark{
  font-family: 'metropill' !important;
  text-transform: uppercase;
}
.w-form-bmi .bmi-section-main .floating-label label{
  font-family: 'metropillthin' !important;
}
.w-form-bmi .bmi-section-main section.bmi-result p,
.w-form-bmi .bmi-section-main span,
.w-form-bmi .bmi-section-main a {
  font-family: 'metropill' !important;
}
.w-form-bmi .bmi-section-main button.ant-switch.bmi-switch.css-dev-only-do-not-override-ccdg5a.ant-switch-checked,
.w-form-bmi .bmi-section-main button.ant-switch.bmi-switch.css-dev-only-do-not-override-ccdg5a,
.w-form-bmi .bmi-section-main button.ant-switch{
  border-radius: 3px;
  box-shadow: none !important;
}
.w-form-bmi .bmi-section-main button.ant-switch .ant-switch-handle::before,
.w-form-bmi .bmi-section-main button.ant-switch.bmi-switch.css-dev-only-do-not-override-ccdg5a::before,
.w-form-bmi .bmi-section-main :where(.css-dev-only-do-not-override-ccdg5a).ant-switch .ant-switch-handle::before{
  border-radius: 3px;
}
.w-form-bmi .btncust,
.w-form.w-form-bmi button{
  font-family: 'metropill' !important;
}
.btncust{
  font-family: 'metropill' !important;
}
.verification-options{
  display: flex;
}
.upload-option-wrap .top-section-img {
  display: flex;
  gap: 30px;
}
.upload-option-wrap .top-section-img img{
  max-height: 200px;
}
.wrap-option-up {
  width: 50%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: auto !important;
  margin: 16px;
  border: 1px solid #000;
  /* border-radius: 8px; */
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent !important;
  text-align: center;
  min-height: 100px;
}
.width-drag .preview-img {
  border: 1px solid #1e2926;
  border-radius: 12px;
  background-color: #fff;
  padding: 10px;
  width: 500px;
  margin: 0 auto;
  position: relative;
}
.width-drag .preview-img button.remove-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0 !important;
  border: 0 !important;
  font-size: 14px;
  height: 25px;
  width: 25px;
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.width-drag .preview-img img {
  width: 100%;
  /* height: 210px !important; */
  border-radius: 12px;
  object-fit: contain;
  object-position: top;
}
.w-form .form-control:focus{
  background: transparent !important;
}
.btn-disable{
  font-family: 'metropill' !important;
}