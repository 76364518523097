.w-form {
  max-width: 600px;
  max-width: 650px;
  width: 650px;
  margin: 0 auto;
  padding: 2rem;
}

.form-title {
  font-size: 24px;
  font-weight: 500;
  color: #2D3436;
  margin-bottom: 1.5rem;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-label {
  background: #F8F9FA;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
}

.radio-label.active-check {
  background: #F1F7FF;
  border-color: #0066FF;
}

.radio-label .radio-circle {
  width: 24px;
  height: 24px;
  border: 2px solid #CED4DA;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-label.active-check .radio-circle {
  border-color: #0066FF;
}

.radio-label.active-check .radio-circle::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #0066FF;
  border-radius: 50%;
}

.radio-text {
  font-size: 16px;
  color: #2D3436;
}

.form-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.next-button {
  background: #2D3436;
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.next-button:disabled {
  background: #E9ECEF;
  cursor: not-allowed;
}

/* Additional form styles */
.additional-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
}

.form-control {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #b8adad;
  background: transparent;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  resize: none;
}

.form-control:focus {
  outline: none;
  border-color: #0066FF;
}

.form-control::placeholder {
  color: #ADB5BD;
}

textarea.form-control {
  min-height: 100px;
}

.submit-button {
  background: #0066FF;
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  width: auto;
}
.btncust{
    margin: 0 0 0 auto;
}
.submit-button:disabled {
  background: #E9ECEF;
  cursor: not-allowed;
}

/* Animation */
.fade-in {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 

.alert-infos{
  background-color: #e2b9b3;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0;
}

/* .text-purple {
  color: #6B3E89;
}

.btn-outline-purple {
  color: #6B3E89;
  border: 1px solid #6B3E89;
  background: transparent;
  padding: 10px 20px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.btn-outline-purple:hover:not(:disabled) {
  background: #6B3E89;
  color: white;
}

.btn-outline-purple:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.form-check-input:checked {
  background-color: #6B3E89;
  border-color: #6B3E89;
  } */


/* Not Eligible Component Styles */
.not-eligible-container {
  /* background: linear-gradient(145deg, #ffffff, #f8f9fa); */
  border-radius: 20px;
  padding: 1.5rem;
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05); */
  text-align: center;
  position: relative;
  overflow: hidden;
}

.error-icon {
  color: #e2b9b3;
  animation: pulse 2s infinite;
}

.title {
  color: #2D3436;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  /* background: linear-gradient(45deg, #2D3436, #e2b9b3); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: #000;
}

.message-box {
  margin: 2rem 0;
}

.main-message {
  font-size: 1.1rem;
  color: #2D3436;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.warning-box {
  background: linear-gradient(135deg, #e2b9b3, #f8d7d3);
  padding: 1.5rem;
  border-radius: 12px;
  margin: 1.5rem 0;
}

.warning-text {
  color: #2D3436;
  font-size: 0.95rem;
  margin: 0;
}

.checkbox-wrapper {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #e2b9b3;
  border-radius: 4px;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #e2b9b3;
  border-color: #e2b9b3;
}

.checkbox-label {
  color: #2D3436;
  font-size: 0.95rem;
  cursor: pointer;
}

.review-button {
  background: linear-gradient(135deg, #e2b9b3, #d4a59a);
  color: white;
  border: none;
  padding: 1rem 2rem;
  /* border-radius: 30px; */
  font-weight: 500;
  width: 100%;
  margin: 1rem 0;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: 'metropillthin' !important;
}

.review-button:disabled {
  background: #f1f1f1;
  cursor: not-allowed;
  color: #000;
}

.review-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(226, 185, 179, 0.4);
}

.home-link {
  color: #2D3436;
  text-decoration: none;
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  font-family: 'metropill' !important;
}

.home-link:hover {
  color: #e2b9b3;
  text-decoration: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}