.closeicon{
    padding: 0px;
    background: transparent;
    border: 0px;
    position: absolute;
    top: 25px;
    right: 25px;
}

.closeicon span{
    display: block;
    transition: .5s all;
}

.closeicon:hover span{
    transform: rotate(-180deg);
    color: #e2b9b3;
}

.closeicon span{
    font-size: 26px;
    color: #fff;
}

.ant-drawer-header{
    display: none !important;
}

.bg-drawer{
    background-color: #3c3c3c !important;
}


.w-28rem{
    width: 28rem;
    margin: 0 auto;
}

.ul-menu{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.ul-menu li a{
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8em;
    letter-spacing: 0.15em;
    transform: .5s all;
    text-decoration: none;
}

.ul-menu li a:hover{
    color: #e2b9b3;
}


.cust-accord .accordion-button{
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5em;
    letter-spacing: 0.15em;
    box-shadow: none !important;
}

.cust-accord .accordion-button:hover{
    color: #e2b9b3;
}

.cust-accord .accordion-button{
    position: relative;
}

.cust-accord .accordion-button::after{
    content: '';
    position: absolute;
    top: 13px;
    right: 1px;
    width: 16px;
    height: 1px;
    background-color: #fff;
}

.cust-accord .accordion-button:hover::after{
    background-color: #e2b9b3;
}

.cust-accord .accordion-button::before{
    content: '';
    position: absolute;
    top: 6px;
    right: 8px;
    width: 2px;
    height: 15px;
    background-color: #fff;
}

.cust-accord .accordion-button:hover::before{
    background-color: #e2b9b3;
}

.cust-accord .accordion-button:not(.collapsed)::after{
    background-image: none !important;
    transform: none !important;
}

.cust-accord .accordion-button:not(.collapsed)::before{
    background-image: none !important;
    transform: none !important;
    opacity: 0 !important;
}

.fs-16{
    font-size: 16px !important;
}


.social-icon{
    font-size: 24px;
    color: #fff;
    transition: .5s all;
    
}

.social-icon:hover{
    color: #e2b9b3;
}
.ant-drawer.ant-drawer-top.css-dev-only-do-not-override-ccdg5a.ant-drawer-open {
    z-index: 99999999;
}