.table-header {
    background-color: #202f32; /* Dark header background */
    color: white;
    padding: 20px;
    font-size: 20px;
}
.table-header tr th{
    font-weight: 100;
}
.product-image img {
    width: 50px; /* Adjust as needed */
    height: auto;
}
.product-name {
    color: #323333;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;

}
.price,
.subtotal {
    color: #555;
    font-size: 16px;
}
.table td{
    vertical-align: middle;
}

.cart-totals {
    margin-top: 20px;
    padding: 20px;
}
.total-row{
    border-bottom: 0.01rem solid #e4dfdf;
}
.totel-left-heading{
    padding: 15px;
    background-color: #202f32;
    color: white;
}
.totel-left-heading span{
    text-align: left;
    font-weight: 300;
    font-size: 18px;
}
.totel-right-content{
    padding: 15px;
}
.cart-totals h2 {
    margin-bottom: 20px;
}
.cart-totals .total {
    font-size: 1.5em;
    font-weight: bold;
}
.btn-show.btn-checkout{
    text-align: center !important;
    padding: 20px 30px !important;
}
.error-desc{
    color: #202f32;
    margin-top: 1em;
    margin-bottom: 2em;
    text-transform: capitalize;
    letter-spacing: 1px;
    line-height: 1.8em;
}
.ant-popconfirm-description{
    font-family: 'metropill';
}
.ant-btn-primary{
    color: #fff;
    background: #202f32;
}
.ant-btn-primary:hover{
    color: #fff !important ;
    background: #e2b9b3 !important;
}
.ant-btn-primary:active{
    color: #fff !important;
    background: #e2b9b3 !important;
}

.ant-btn-primary:focus{
    color: #fff !important ;
    background: #e2b9b3 !important;
}

.ant-btn-default{
    color: #202f32;
    border-color: #202f32;
    background: #ffffff;
}
.ant-btn-default:hover{
    color: #e2b9b3 !important;
    border-color: #e2b9b3 !important;
    background: #ffffff !important;
}
.anticon svg{
    color: #e2b9b3 !important;
}
.ant-popconfirm-buttons button{
    border-radius: 0px !important;
}

@media (max-width: 500px) {
    .totel-left-heading{
        padding: 10px !important;
    }
}