.footer-heading{
    color: #141413;
    font-size: 15px;
    font-weight: 600;
}
.list-item{
    justify-content: flex-start;
    text-align: left;
}
.list-icon i{
    color: #D17B4C;
    transition: color 0.3s;
}
.list-text{
    font-size: 12px;
    font-weight: 300;
    line-height: 1.6em;
    letter-spacing: 0px;
    color: #696969;
    transition: color 0.3s;
    padding-inline-start: 7px;
}
.list-link{
    text-decoration: none;
}
.list-link:hover {
    color: inherit; /* Keep link color same on hover */
    text-decoration: none; /* Ensure no underline on hover */
}

.list-link:hover .list-text {
    color: #D17B4C;
}
.heading-title{
    color: #060606;
    font-size: 13px;
    font-weight: 400;
}
.heading-title2{
    color: #060606;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}
.social-icon{
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.inline-items-list{
    display: flex;
    flex-wrap: wrap;
}