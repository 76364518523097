.tag-btnpro {
    padding: 4px 10px;
    font-size: 13px;
    font-weight: 400;
    background-color:  #e2b9b3;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btncust-1{
    padding: 10px 30px;
    border-radius: 30px;
    border: 2px solid #D17C4C;
    text-align: center;
    display: block;
    background-color: transparent;
    color: #b88539;
    transition: .3s all;
}

.btncust-1:hover{
    background-color: #b88539;
    color: #fff;
}
.btn-pink{
    padding: 10px 30px;
    border: 2px solid #e2b9b3;
    text-align: center;
    display: block;
    background-color: #e2b9b3;
    color: #fff;
    transition: .3s all;
}

.btn-pink .colorload svg{
    color: #202f32 !important;
}

.fs-14{
    font-size: 14px;
}
.fs-18{
    font-size: 18px;
}
@media (max-width: 991px){
    .w-80s{
        width: 100%;
    }
    .bg-banner{
        margin-top: 0px !important;
    }
}
.del-line-color {
    text-decoration-color: #fcccc5; /* Set the color you desire for the strikethrough */
    text-decoration-thickness: 2px; /* Optional: to set the thickness of the line */
}
.ant-form-item-label{
    text-align: start !important;
}

.extra-large-button {
    border-radius: 0px !important;
    font-size: 25px !important;
    padding: 0px 50px !important;
}


.custom-picker-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.custom-icon {
    position: absolute;
    left: 10px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.25);
}

.custom-picker {
    padding-left: 30px; /* Add padding to make room for the icon */
}

/* Hide the default right-side icon */
.custom-picker .ant-picker-suffix {
    display: none;
}

.custom-time{
    background: transparent;
    border: 1px solid #e1dcdc;
    border-radius: 5px;
    padding: 5px;
    padding-left: 40px !important;
    -webkit-appearance: none; /* Remove default webkit appearance (for Safari) */
    -moz-appearance: textfield; /* Remove default appearance (for Firefox) */
    appearance: none; /* Remove default appearance (for modern browsers) */
    position: relative;
}
.custom-time::-webkit-calendar-picker-indicator {
    display: none; /* Hide the default time picker icon */
}
.custom-picker-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.custom-time-icon {
    position: absolute;
    left: 10px; /* Adjust as necessary */
    z-index: 1;
}

.custom-picker {
    padding-left: 40px; /* Adjust to create space for the icon */
}

.btn-gray{
    padding: 10px 30px;
    border: 2px solid #c7c2c2;
    text-align: center;
    display: block;
    background-color: #c7c2c2;
    color: #fff;
    transition: .3s all;
}

  
@media (max-width: 768px) {
    /* Disable input focus on mobile devices for react-select */
    .time-wrapper .react-select__input input {
      pointer-events: none;
    }
}
/* Hide the default required asterisk */
.custom-email-form-item .ant-form-item-required::before {
    display: none !important;
}

